import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from './firebase';
import { 
    isSignInWithEmailLink, 
    signInWithEmailLink,
    signOut
} from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isPasswordSet, setIsPasswordSet] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Handle email link sign-in
    useEffect(() => {
        const handleEmailLink = async () => {
            if (isSignInWithEmailLink(auth, window.location.href)) {
                setLoading(true);  // Set loading true while processing
                let email = window.localStorage.getItem('emailForSignIn');
                
                if (!email) {
                    email = window.prompt('Please provide your email for confirmation');
                }

                try {
                    await signInWithEmailLink(auth, email, window.location.href);
                    window.localStorage.removeItem('emailForSignIn');
                    // Success case is handled by onAuthStateChanged
                } catch (error) {
                    console.error('Error signing in with email link:', error);
                    // Don't navigate on error, just set loading to false
                    setLoading(false);
                }
            }
        };

        handleEmailLink();
    }, []);

    // Listen for auth state changes
    useEffect(() => {
        let isInitialMount = true;

        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            console.log('Auth state changed:', { 
                user: !!user, 
                pathname: location.pathname,
                isInitialMount 
            });
            
            setUser(user);
            
            if (user) {
                try {
                    const userDoc = await getDoc(doc(db, 'users', user.uid));
                    setUserProfile(userDoc.data()?.userProfile);

                    // Check if user is using a temporary password
                    const isTemp = userDoc.data()?.isTemporaryPassword;
                    setIsPasswordSet(!isTemp);

                    // If using temporary password, redirect to setup
                    if (isTemp) {
                        navigate('/setup', { 
                            state: { email: user.email },
                            replace: true 
                        });
                    }
                } catch (error) {
                    console.error("Error checking user profile:", error);
                }
            }
            setLoading(false);
            
            // Only navigate if user is authenticated
            if (isInitialMount && user && ['/login', '/signup'].includes(location.pathname)) {
                console.log('Navigating to dashboard...');
                navigate('/dashboard', { replace: true });
            }
            isInitialMount = false;
        });

        return () => unsubscribe();
    }, [navigate, location.pathname]);

    // Sign out function
    const signOut = async () => {
        try {
            await auth.signOut();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
            throw error;
        }
    };

    const value = {
        user,
        userProfile,
        isAuthenticated: !!user,
        loading,
        signOut,
        isPasswordSet
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the auth context
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === null) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
