import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './FollowersPage.css';
import { Link } from 'react-router-dom';
import { 
    followUser, 
    unfollowUser, 
    isFollowing as checkIsFollowing 
} from '../services/followService';

const FollowersPage = () => {
    const { username } = useParams(); // Get username from URL if viewing someone else's followers
    const { user, isAuthenticated } = useAuth();
    const [followers, setFollowers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [followStatus, setFollowStatus] = useState({});
    const [hoverStatus, setHoverStatus] = useState({});
    const navigate = useNavigate();

    const defaultAvatar = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media";

    useEffect(() => {
        const fetchFollowers = async () => {
            try {
                let targetUserId;

                if (username) {
                    // If viewing someone else's followers, get their user ID first
                    const usersRef = collection(db, 'users');
                    const q = query(usersRef, where('userProfile.username', '==', username));
                    const userSnapshot = await getDocs(q);
                    
                    if (!userSnapshot.empty) {
                        targetUserId = userSnapshot.docs[0].id;
                    } else {
                        setError('User not found');
                        setLoading(false);
                        return;
                    }
                } else if (isAuthenticated) {
                    // If no username provided and user is authenticated, show their own followers
                    targetUserId = user.uid;
                } else {
                    setError('User not found');
                    setLoading(false);
                    return;
                }

                // Fetch followers
                const followsRef = collection(db, 'follows');
                const q = query(followsRef, where('followingId', '==', targetUserId));
                const querySnapshot = await getDocs(q);
                
                // Get follower details
                const followerPromises = querySnapshot.docs.map(async (docSnapshot) => {
                    try {
                        const followerDoc = await getDoc(doc(db, 'users', docSnapshot.data().followerId));
                        if (!followerDoc.exists()) {
                            console.log('No user document found for ID:', docSnapshot.data().followerId);
                            return null;
                        }
                        return {
                            id: docSnapshot.data().followerId,
                            ...followerDoc.data().userProfile
                        };
                    } catch (err) {
                        console.error('Error fetching user document:', err);
                        return null;
                    }
                });

                const followerDetails = (await Promise.all(followerPromises)).filter(Boolean);
                setFollowers(followerDetails);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching followers:', error);
                setError('Error loading followers');
                setLoading(false);
            }
        };

        fetchFollowers();
    }, [username, user, isAuthenticated]);

    useEffect(() => {
        const checkFollowStatuses = async () => {
            if (isAuthenticated && user && followers.length > 0) {
                const statuses = {};
                for (const follower of followers) {
                    try {
                        const isFollowing = await checkIsFollowing(user.uid, follower.id);
                        statuses[follower.id] = isFollowing;
                    } catch (error) {
                        console.error('Error checking follow status:', error);
                    }
                }
                setFollowStatus(statuses);
            }
        };

        checkFollowStatuses();
    }, [followers, isAuthenticated, user]);

    const handleFollowToggle = async (followerId) => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        try {
            const currentlyFollowing = followStatus[followerId];
            if (currentlyFollowing) {
                await unfollowUser(user.uid, followerId);
            } else {
                await followUser(user.uid, followerId);
            }
            setFollowStatus(prev => ({
                ...prev,
                [followerId]: !currentlyFollowing
            }));
        } catch (error) {
            console.error('Error updating follow status:', error);
        }
    };

    const FollowerItem = ({ follower }) => {
        return (
            <div className="follower-item">
                <Link to={`/user/${follower.username}`} className="follower-link">
                    <img 
                        src={follower.profileImageUrl || defaultAvatar}
                        alt={`${follower.username}'s profile`}
                        className="follower-avatar"
                        onError={(e) => {
                            e.target.src = defaultAvatar; // Fallback if image fails to load
                        }}
                    />
                    <span className="follower-username">{follower.username}</span>
                </Link>
            </div>
        );
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <h1>{username ? `${username}'s Followers` : 'Your Followers'}</h1>
                    {loading ? (
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                            <div>Loading...</div>
                        </div>
                    ) : error ? (
                        <div className="error-message">{error}</div>
                    ) : (
                        <div className="followers-list">
                            {followers.length === 0 ? (
                                <div className="no-followers">
                                    {username ? 
                                        `${username} doesn't have any followers yet` : 
                                        "You don't have any followers yet"}
                                </div>
                            ) : (
                                followers.map(follower => (
                                    <div key={follower.id} className="follower-item">
                                        <Link to={`/user/${follower.username}`} className="follower-avatar">
                                            <img 
                                                src={follower.profileImageUrl || defaultAvatar} 
                                                alt={follower.username}
                                                className="avatar-image"
                                            />
                                        </Link>
                                        <div className="follower-details">
                                            <Link to={`/user/${follower.username}`} className="follower-username">
                                                {follower.username}
                                            </Link>
                                            {follower.name && (
                                                <div className="follower-name">{follower.name}</div>
                                            )}
                                        </div>
                                        {user?.uid !== follower.id && (
                                            isAuthenticated ? (
                                                <button 
                                                    className={`follow-button ${followStatus[follower.id] ? 'is-following' : ''}`}
                                                    onClick={() => handleFollowToggle(follower.id)}
                                                    onMouseEnter={() => setHoverStatus(prev => ({ ...prev, [follower.id]: true }))}
                                                    onMouseLeave={() => setHoverStatus(prev => ({ ...prev, [follower.id]: false }))}
                                                >
                                                    {followStatus[follower.id] 
                                                        ? (hoverStatus[follower.id] ? 'Unfollow' : 'Following')
                                                        : 'Follow'}
                                                </button>
                                            ) : (
                                                <button 
                                                    className="follow-button"
                                                    onClick={() => navigate('/login')}
                                                >
                                                    Follow
                                                </button>
                                            )
                                        )}
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default FollowersPage;
