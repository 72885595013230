import React, { useState, useEffect } from 'react';
import { doc, runTransaction, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

const PostVote = ({ postId, communityName, initialVoteCount, onVoteChange }) => {
    const [voteCount, setVoteCount] = useState(initialVoteCount);
    const [userVote, setUserVote] = useState(0);
    const { user, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    // Debug log props
    console.log('PostVote Props:', {
        postId,
        communityName,
        initialVoteCount,
        isAuthenticated,
        userId: user?.uid
    });

    useEffect(() => {
        const loadUserVote = async () => {
            if (!user) {
                console.log('No user logged in');
                return;
            }
            
            try {
                console.log('Loading user vote for:', {
                    communityName,
                    postId,
                    userId: user.uid
                });

                const postVoteRef = doc(db, 'communities', communityName.toLowerCase(), 'posts', postId, 'votes', user.uid);
                const voteDoc = await getDoc(postVoteRef);
                
                console.log('Existing vote document:', voteDoc.exists() ? voteDoc.data() : 'No vote found');
                
                if (voteDoc.exists()) {
                    setUserVote(voteDoc.data().value);
                }
            } catch (error) {
                console.error('Error loading vote data:', error);
            }
        };

        loadUserVote();
    }, [postId, communityName, user]);

    const handleVote = async (voteValue) => {
        if (!isAuthenticated) {
            console.log('User not authenticated, redirecting to login');
            navigate('/login');
            return;
        }

        console.log('Handling vote:', {
            voteValue,
            postId,
            communityName,
            userId: user.uid
        });

        try {
            await runTransaction(db, async (transaction) => {
                // 1. Get post document
                const postRef = doc(db, 'communities', communityName.toLowerCase(), 'posts', postId);
                const postDoc = await transaction.get(postRef);

                console.log('Post document:', postDoc.exists() ? postDoc.data() : 'Not found');

                if (!postDoc.exists()) {
                    throw new Error('Post not found!');
                }

                // 2. Get user's vote document
                const postVoteRef = doc(db, 'communities', communityName.toLowerCase(), 'posts', postId, 'votes', user.uid);
                const voteDoc = await transaction.get(postVoteRef);

                console.log('Current vote document:', voteDoc.exists() ? voteDoc.data() : 'No existing vote');

                const currentVoteCount = postDoc.data().voteCount || 0;
                let newVoteCount = currentVoteCount;
                
                if (voteDoc.exists()) {
                    const existingVote = voteDoc.data().value;
                    
                    if (existingVote === voteValue) {
                        // Remove vote
                        console.log('Removing vote');
                        transaction.delete(postVoteRef);
                        newVoteCount = currentVoteCount - voteValue;
                        transaction.update(postRef, {
                            voteCount: newVoteCount
                        });
                        setUserVote(0);
                    } else {
                        // Change vote
                        console.log('Changing vote from', existingVote, 'to', voteValue);
                        transaction.update(postVoteRef, {
                            value: voteValue,
                            updatedAt: new Date()
                        });
                        newVoteCount = currentVoteCount - existingVote + voteValue;
                        transaction.update(postRef, {
                            voteCount: newVoteCount
                        });
                        setUserVote(voteValue);
                    }
                } else {
                    // Create new vote
                    console.log('Creating new vote');
                    transaction.set(postVoteRef, {
                        value: voteValue,
                        createdAt: new Date(),
                        updatedAt: new Date()
                    });
                    newVoteCount = currentVoteCount + voteValue;
                    transaction.update(postRef, {
                        voteCount: newVoteCount
                    });
                    setUserVote(voteValue);
                }
                
                console.log('New vote count:', newVoteCount);
                setVoteCount(newVoteCount);
                if (onVoteChange) {
                    onVoteChange(newVoteCount);
                }
            });
        } catch (error) {
            console.error('Error voting:', error);
        }
    };

    return (
        <div className="vote-container">
            <button 
                onClick={() => handleVote(1)}
                className={`vote-button upvote ${userVote === 1 ? 'active-upvote' : ''}`}
            >
                ▲
            </button>
            <span className="vote-count">{voteCount}</span>
            <button 
                onClick={() => handleVote(-1)}
                className={`vote-button downvote ${userVote === -1 ? 'active-downvote' : ''}`}
            >
                ▼
            </button>
        </div>
    );
};

export default PostVote;
