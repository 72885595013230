import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './UserProfile.css';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { sendMessage } from '../services/messageService';
import MessageModal from '../components/MessageModal';
import { 
    followUser, 
    unfollowUser, 
    isFollowing as checkIsFollowing 
} from '../services/followService';
import ReportModal from '../components/ReportModal';
import '../styles/SharedStyles.css';

const UserProfile = () => {
    const { username } = useParams();
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const { isAuthenticated, user } = useAuth();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const [followerCount, setFollowerCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);
    const [showReportModal, setShowReportModal] = useState(false);

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                console.log('Fetching profile for username:', username);
                
                const usersSnapshot = await getDocs(collection(db, 'users'));
                let foundProfile = null;

                usersSnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (data.userProfile && data.userProfile.username === username) {
                        foundProfile = {
                            ...data.userProfile,
                            profileImageUrl: data.userProfile.profileImageUrl || null,
                            userId: doc.id
                        };
                    }
                });

                if (!foundProfile) {
                    console.log('No user found with username:', username);
                    setError('User not found');
                } else {
                    console.log('Found user profile:', foundProfile);
                    setProfileData(foundProfile);
                }
            } catch (err) {
                console.error('Error fetching user profile:', err);
                setError('Error loading profile');
            } finally {
                setLoading(false);
            }
        };

        if (username) {
            fetchUserProfile();
        }
    }, [username]);

    useEffect(() => {
        let isMounted = true;

        const checkFollowStatus = async () => {
            if (isAuthenticated && user && profileData) {
                try {
                    console.log('Starting follow status check');
                    const following = await checkIsFollowing(user.uid, profileData.userId);
                    if (isMounted) {
                        console.log('Setting follow status:', following);
                        setIsFollowing(following);
                    }
                } catch (error) {
                    console.error('Error checking follow status:', error);
                }
            }
        };

        checkFollowStatus();

        return () => {
            isMounted = false;
        };
    }, [isAuthenticated, user, profileData]);

    useEffect(() => {
        const fetchFollowCounts = async () => {
            if (profileData) {
                try {
                    // Get followers count
                    const followersQuery = query(
                        collection(db, 'follows'),
                        where('followingId', '==', profileData.userId)
                    );
                    const followersSnapshot = await getDocs(followersQuery);
                    setFollowerCount(followersSnapshot.size);

                    // Get following count
                    const followingQuery = query(
                        collection(db, 'follows'),
                        where('followerId', '==', profileData.userId)
                    );
                    const followingSnapshot = await getDocs(followingQuery);
                    setFollowingCount(followingSnapshot.size);
                } catch (error) {
                    console.error('Error fetching follow counts:', error);
                }
            }
        };

        fetchFollowCounts();
    }, [profileData]);

    const handleMessageClick = () => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }
        setIsModalOpen(true);
    };

    const handleSendMessage = async (messageText) => {
        try {
            const usersSnapshot = await getDocs(collection(db, 'users'));
            let recipientId = null;

            usersSnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.userProfile && data.userProfile.username === username) {
                    recipientId = doc.id;
                }
            });

            if (!recipientId) {
                throw new Error('Recipient not found');
            }

            await sendMessage(
                user.uid,
                recipientId,
                messageText
            );
            
            return true;
        } catch (error) {
            console.error('Error sending message:', error);
            throw error;
        }
    };

    const getButtonText = () => {
        if (!isFollowing) return 'Follow';
        return isHovering ? 'Unfollow' : 'Following';
    };

    const handleFollow = async () => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        try {
            console.log('Current follow state:', isFollowing);
            if (isFollowing) {
                await unfollowUser(user.uid, profileData.userId);
                console.log('Unfollowed successfully');
                // Decrease follower count
                setFollowerCount(prevCount => prevCount - 1);
            } else {
                await followUser(user.uid, profileData.userId);
                console.log('Followed successfully');
                // Increase follower count
                setFollowerCount(prevCount => prevCount + 1);
            }
            setIsFollowing(!isFollowing);
            console.log('New follow state:', !isFollowing);
        } catch (error) {
            console.error('Error updating follow status:', error);
        }
    };

    useEffect(() => {
        console.log('Button States:', { isFollowing, isHovering });
    }, [isFollowing, isHovering]);

    const renderReportButton = () => {
        if (isOwnProfile || !isAuthenticated) return null;
        
        return (
            <>
                <button 
                    className="report-profile-button"
                    onClick={() => setShowReportModal(true)}
                >
                    <i className="fas fa-flag"></i>
                    Report Profile
                </button>
                
                <ReportModal
                    isOpen={showReportModal}
                    onClose={() => setShowReportModal(false)}
                    contentType="profile"
                    contentData={{
                        userId: profileData.id,
                        profileUrl: window.location.href
                    }}
                />
            </>
        );
    };

    const getYoutubeEmbedUrl = (url) => {
        const videoId = getYoutubeVideoId(url);
        return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
    };

    const getYoutubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    if (loading) return (
        <>
            <Header />
            <main className="profile-container">
                <div>Loading...</div>
            </main>
            <Footer />
        </>
    );

    if (error) return (
        <>
            <Header />
            <main className="profile-container">
                <div>{error}</div>
            </main>
            <Footer />
        </>
    );

    if (!profileData) return (
        <>
            <Header />
            <main className="profile-container">
                <div>Profile not found</div>
            </main>
            <Footer />
        </>
    );

    const isOwnProfile = user && user.uid === profileData.userId;

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="page-header">
                        <div className="title-section">
                            <h1>{`${profileData.firstName || ''} ${profileData.middleName || ''} ${profileData.lastName || ''}`}</h1>
                            <div className="username-display">@{profileData.username}</div>
                        </div>
                        {isOwnProfile && (
                            <button 
                                className="button-secondary"
                                onClick={() => navigate('/settings/profile')}
                            >
                                Edit Profile
                            </button>
                        )}
                    </div>

                    <div className="profile-layout">
                        {/* Left Column - Profile Image */}
                        <div className="profile-sidebar">
                            <div className="profile-image-container">
                                <img 
                                    src={profileData.profileImageUrl || 'https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074'} 
                                    alt="Profile" 
                                    className="profile-image"
                                />
                            </div>
                            <div className="follow-stats">
                                <Link to={`/user/${profileData.username}/following`} className="follow-stat">
                                    <span className="follow-count">{followingCount}</span>
                                    <span className="follow-label">Following</span>
                                </Link>
                                <Link to={`/user/${profileData.username}/followers`} className="follow-stat">
                                    <span className="follow-count">{followerCount}</span>
                                    <span className="follow-label">Followers</span>
                                </Link>
                            </div>
                            {!isOwnProfile && (
                                <>
                                    <button 
                                        className="message-button"
                                        onClick={handleMessageClick}
                                    >
                                        Send Message
                                    </button>
                                    <button 
                                        className={`follow-button ${isFollowing ? 'is-following' : ''}`}
                                        onClick={handleFollow}
                                        onMouseEnter={() => setIsHovering(true)}
                                        onMouseLeave={() => setIsHovering(false)}
                                    >
                                        {getButtonText()}
                                    </button>
                                </>
                            )}
                            {renderReportButton()}
                        </div>

                        {/* Right Column - Profile Information */}
                        <div className="profile-content">
                            <h2>Personal Information</h2>
                            <div className="settings-grid">
                                {profileData.firstName && (
                                    <div className="profile-field-row">
                                        <span className="profile-field-label">First Name:</span>
                                        <span className="profile-field-value">{profileData.firstName}</span>
                                    </div>
                                )}
                                
                                {profileData.middleName && (
                                    <div className="profile-field-row">
                                        <span className="profile-field-label">Middle Name:</span>
                                        <span className="profile-field-value">{profileData.middleName}</span>
                                    </div>
                                )}
                                
                                {profileData.lastName && (
                                    <div className="profile-field-row">
                                        <span className="profile-field-label">Last Name:</span>
                                        <span className="profile-field-value">{profileData.lastName}</span>
                                    </div>
                                )}

                                {profileData.location && (
                                    <div className="profile-field-row">
                                        <span className="profile-field-label">Location:</span>
                                        <span className="profile-field-value">{profileData.location}</span>
                                    </div>
                                )}
                                
                                {profileData.yearOfBirth && (
                                    <div className="profile-field-row">
                                        <span className="profile-field-label">Year of Birth:</span>
                                        <span className="profile-field-value">{profileData.yearOfBirth}</span>
                                    </div>
                                )}
                                
                                {profileData.gender && (
                                    <div className="profile-field-row">
                                        <span className="profile-field-label">Gender:</span>
                                        <span className="profile-field-value">{profileData.gender}</span>
                                    </div>
                                )}
                                
                                {profileData.height && (
                                    <div className="profile-field-row">
                                        <span className="profile-field-label">Height:</span>
                                        <span className="profile-field-value">{profileData.height}</span>
                                    </div>
                                )}
                                
                                {profileData.weight && (
                                    <div className="profile-field-row">
                                        <span className="profile-field-label">Weight:</span>
                                        <span className="profile-field-value">{profileData.weight}</span>
                                    </div>
                                )}
                                {profileData.handedness && (
                                    <div className="profile-field-row">
                                        <span className="profile-field-label">Handedness:</span>
                                        <span className="profile-field-value">{profileData.handedness}</span>
                                    </div>
                                )}
                            </div>


                            {(profileData.instagramAccount || profileData.twitterAccount) && (
                                <>
                                    <h2>Social Media</h2>
                                    <div className="settings-grid">
                                        {profileData.instagramAccount && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">Instagram:</span>
                                                <span className="profile-field-value">
                                                    <a 
                                                        href={`https://instagram.com/${profileData.instagramAccount.replace('@', '')}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {profileData.instagramAccount}
                                                    </a>
                                                </span>
                                            </div>
                                        )}
                                        
                                        {profileData.twitterAccount && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">Twitter:</span>
                                                <span className="profile-field-value">
                                                    <a 
                                                        href={`https://twitter.com/${profileData.twitterAccount.replace('@', '')}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {profileData.twitterAccount}
                                                    </a>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                            {(profileData.coach || profileData.primaryClub || profileData.secondaryClub || profileData.isFencer || profileData.isCoach) && (
                                <>
                                    <h2>Club Information</h2>
                                    <div className="settings-grid">
                                        {(profileData.isFencer || profileData.isCoach) && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">Membership:</span>
                                                <span className="profile-field-value membership-tags">
                                                    {profileData.isFencer && <span className="role-tag">Fencer</span>}
                                                    {profileData.isCoach && <span className="role-tag">Coach</span>}
                                                </span>
                                            </div>
                                        )}
                                        {profileData.coach && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">Coach:</span>
                                                <span className="profile-field-value">{profileData.coach}</span>
                                            </div>
                                        )}
                                        {profileData.primaryClub && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">Primary Club:</span>
                                                <span className="profile-field-value">{profileData.primaryClub}</span>
                                            </div>
                                        )}
                                        
                                        {profileData.secondaryClub && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">Secondary Club:</span>
                                                <span className="profile-field-value">{profileData.secondaryClub}</span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                            {(profileData.occupation || profileData.education || profileData.milestones || profileData.biography || profileData.fencingTrackerUrl) && (
                                <>
                                    <h2>Bio</h2>
                                    <div className="settings-grid">
                                        {profileData.occupation && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">Occupation:</span>
                                                <span className="profile-field-value">{profileData.occupation}</span>
                                            </div>
                                        )}
                                        {profileData.education && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">Education:</span>
                                                <span className="profile-field-value">{profileData.education}</span>
                                            </div>
                                        )}
                                        {profileData.milestones && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">Milestones:</span>
                                                <span className="profile-field-value profile-milestones-text">{profileData.milestones}</span>
                                            </div>
                                        )}
                                        {profileData.biography && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">Biography:</span>
                                                <span className="profile-field-value profile-biography-text">{profileData.biography}</span>
                                            </div>
                                        )}
                                        {profileData.fencingTrackerUrl && (
                                            <div className="profile-field-row">
                                                <span className="profile-field-label">FencingTracker URL:</span>
                                                <span className="profile-field-value">
                                                    <a 
                                                        href={profileData.fencingTrackerUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {profileData.fencingTrackerUrl}
                                                    </a>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                            {profileData.videos && profileData.videos.length > 0 && (
                                <>
                                    <h2>Videos</h2>
                                    <div className="videos-grid">
                                        {profileData.videos.map((videoUrl, index) => {
                                            const embedUrl = getYoutubeEmbedUrl(videoUrl);
                                            if (!embedUrl) return null;
                                            
                                            return (
                                                <div key={index} className="video-container">
                                                    <iframe
                                                        width="100%"
                                                        height="315"
                                                        src={embedUrl}
                                                        title={`YouTube video ${index + 1}`}
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <MessageModal 
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    recipientUsername={username}
                    onSendMessage={handleSendMessage}
                />
                <ReportModal
                    isOpen={showReportModal}
                    onClose={() => setShowReportModal(false)}
                    contentType="profile"
                    contentData={{
                        userId: profileData.id,
                        profileUrl: window.location.href
                    }}
                />
            </main>
            <Footer />
        </>
    );
};

export default UserProfile;
