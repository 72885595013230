// src/HomePage.js
import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams, Link } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './HomePage.css';
import { collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { formatDistanceToNow } from 'date-fns';
import { db } from '../firebase';
import PostVote from '../components/PostVote';

const HomePage = () => {
    const { user } = useAuth();
    const [searchParams] = useSearchParams();
    const [latestPosts, setLatestPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const mode = searchParams.get('mode');
    const defaultAvatar = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074";

    useEffect(() => {
        const fetchLatestPosts = async () => {
            try {
                setLoading(true);
                const allPosts = [];
                
                // Get all communities
                const communitiesSnapshot = await getDocs(collection(db, 'communities'));
                
                // Fetch posts from each community
                for (const communityDoc of communitiesSnapshot.docs) {
                    const communityData = communityDoc.data();
                    const communityName = communityData.name.toLowerCase();
                    
                    const postsRef = collection(db, 'communities', communityName, 'posts');
                    const postsSnapshot = await getDocs(postsRef);
                    
                    // Process each post
                    for (const postDoc of postsSnapshot.docs) {
                        const postData = postDoc.data();
                        
                        // Get comment count
                        const commentsSnapshot = await getDocs(
                            collection(db, 'communities', communityName, 'posts', postDoc.id, 'comments')
                        );
                        
                        // Only include posts with 2 or more comments
                        if (commentsSnapshot.size >= 2) {
                            let authorData = {
                                username: '[deleted]',
                                profileImageUrl: defaultAvatar
                            };

                            if (postData.authorId) {
                                const authorDoc = await getDoc(doc(db, 'users', postData.authorId));
                                if (authorDoc.exists()) {
                                    authorData = authorDoc.data().userProfile;
                                }
                            }

                            const slug = postData.slug || postData.title.toLowerCase()
                                .replace(/[^a-z0-9]+/g, '-')
                                .replace(/(^-|-$)/g, '');

                            allPosts.push({
                                id: postDoc.id,
                                communityName: communityData.name,
                                authorUsername: authorData.username,
                                authorProfilePhoto: authorData.profileImageUrl || defaultAvatar,
                                ...postData,
                                slug: slug,
                                commentCount: commentsSnapshot.size,
                                createdAt: postData.createdAt?.toDate() || new Date()
                            });
                        }
                    }
                }

                // Sort by most recent activity (using createdAt for now)
                const sortedPosts = allPosts.sort((a, b) => b.createdAt - a.createdAt);
                setLatestPosts(sortedPosts.slice(0, 10)); // Show top 10 most recent posts
                
            } catch (error) {
                console.error('Error fetching latest posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLatestPosts();
    }, []);

    // Redirect logic
    if (user && mode === 'signIn') {
        return <Navigate to="/dashboard" replace />;
    }

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="welcome-section">
                        <h1>FencerStats is a community for fencers, coaches, and fencing enthusiasts.</h1>
                    </div>
                    
                    <div className="latest-activity-section">
                        <h2>Latest Activity</h2>
                        <div className="feed-container">
                            {loading ? (
                                <div className="loading-spinner"></div>
                            ) : latestPosts.length > 0 ? (
                                latestPosts.map(post => (
                                    <FeedPost key={post.id} post={post} />
                                ))
                            ) : (
                                <div className="empty-feed">
                                    <p>No recent activity to display.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

const FeedPost = ({ post }) => {
    return (
        <div className="feed-post">
            <div className="post-header">
                <Link to={`/user/${post.authorUsername}`} className="profile-photo-link">
                    <img src={post.authorProfilePhoto} alt="Profile" className="profile-photo" />
                </Link>
                <div className="post-meta">
                    <Link to={`/user/${post.authorUsername}`} className="username-link">
                        {post.authorUsername}
                    </Link>
                    <span className="post-meta-separator">•</span>
                    <Link to={`/c/${post.communityName}`} className="feed-community-link">
                        r/{post.communityName}
                    </Link>
                    <span className="post-meta-separator">•</span>
                    <span className="post-date">
                        {formatDistanceToNow(post.createdAt)} ago
                    </span>
                </div>
            </div>
            <Link 
                to={`/c/${post.communityName}/posts/${post.id}/${post.slug}`} 
                className="post-content-link"
            >
                <h3 className="post-title">{post.title}</h3>
                <div className="post-text">
                    {post.isHtml ? (
                        <div dangerouslySetInnerHTML={{ __html: post.content }} />
                    ) : (
                        post.content
                    )}
                </div>
            </Link>
            <div className="post-actions">
                <div className="vote-section">
                    <PostVote 
                        postId={post.id}
                        communityName={post.communityName}
                        initialVoteCount={post.voteCount || 0}
                        onVoteChange={(newCount) => {
                            // No need for setFeedPosts since this is the homepage
                            console.log('Vote changed:', newCount);
                        }}
                    />
                </div>
                <Link 
                    to={`/c/${post.communityName}/posts/${post.id}/${post.slug}`} 
                    className="action-button"
                >
                    <i className="far fa-comment"></i>
                    {post.commentCount ? `${post.commentCount} Comments` : '0 Comments'}
                </Link>
            </div>
        </div>
    );
};

export default HomePage;