import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';
import './Header.css';
import { useAuth } from '../AuthContext';
import SearchBox from './SearchBox';
import UserMenu from './UserMenu/UserMenu';

const Header = ({ hideAuthButton = false }) => {
    const { isAuthenticated } = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const shouldHideNavLinks = location.pathname === '/login' || location.pathname === '/signup';

    return (
        <header className="header">
            <div className="header-content">
                <div className="logo">
                    <Link to="/">FencerStats</Link>
                </div>
                
                <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                    {isAuthenticated ? (
                        <>
                            <SearchBox />
                            <Link 
                                to="/settings/profile" 
                                onClick={() => setIsMenuOpen(false)}
                                className="nav-link"
                            >
                                My Profile
                            </Link>
                            {isAuthenticated && (
                                <Link to="/inbox" className="nav-link">Messages</Link>
                            )}
                            <Link 
                                to="/clubs" 
                                className="nav-link"
                            >
                                Clubs
                            </Link>
                            <Link 
                                to="/communities" 
                                className="nav-link"
                            >
                                Communities
                            </Link>
                            <UserMenu />
                        </>
                    ) : (
                        <>
                            {/* <Link to="/about" onClick={() => setIsMenuOpen(false)}>About</Link> */}
                        </>
                    )}
                </nav>

                <button 
                    className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`}
                    onClick={toggleMenu}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                
                {!isAuthenticated && (
                    <div className="auth-buttons">
                        {location.pathname !== '/login' && (
                            <button className="login-button" onClick={() => navigate('/login')}>Login</button>
                        )}
                        {location.pathname !== '/signup' && (
                            <button className="signup-button" onClick={() => navigate('/signup')}>Sign Up</button>
                        )}
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header; 