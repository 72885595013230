import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './ModeratorSearchModal.css';

const defaultProfileImage = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074";

const ModeratorSearchModal = ({ isOpen, onClose, onSave, currentModeratorId, title, description }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSearch = async (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        
        if (term.length < 3) {
            setSearchResults([]);
            return;
        }

        setLoading(true);
        try {
            const usersRef = collection(db, 'users');
            const q = query(
                usersRef,
                where('userProfile.username', '>=', term),
                where('userProfile.username', '<=', term + '\uf8ff')
            );
            
            const querySnapshot = await getDocs(q);
            const results = [];
            querySnapshot.forEach((doc) => {
                const userData = doc.data().userProfile;
                results.push({
                    id: doc.id,
                    username: userData.username,
                    profileImage: userData.profileImageUrl || defaultProfileImage
                });
            });
            setSearchResults(results);
        } catch (error) {
            console.error('Error searching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUserSelect = (user) => {
        setSelectedUser(user);
    };

    if (!isOpen) return null;

    return (
        <div className="moderator-modal-overlay">
            <div className="moderator-modal-content">
                <h2>{title}</h2>
                <p>{description}</p>
                <div className="moderator-search-container">
                    <input
                        type="text"
                        placeholder="Search by username..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="moderator-search-input"
                    />
                    <div className="moderator-search-results">
                        {loading ? (
                            <div className="loading">Searching...</div>
                        ) : (
                            searchResults.map(user => (
                                <div 
                                    key={user.id} 
                                    className="moderator-user-result"
                                    onClick={() => onSave(user.id, user)}
                                >
                                    <img 
                                        src={user.profileImage} 
                                        alt={user.username} 
                                        className="moderator-user-avatar"
                                    />
                                    <span>{user.username}</span>
                                </div>
                            ))
                        )}
                    </div>
                </div>
                <div className="club-edit-modal-buttons">
                    <button 
                        onClick={() => onSave(selectedUser)}
                        disabled={!selectedUser}
                        className="button-primary"
                    >
                        Save
                    </button>
                    <button 
                        onClick={onClose} 
                        className="button-secondary"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ModeratorSearchModal; 