import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs, orderBy, limit, getDoc, doc } from 'firebase/firestore';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './Dashboard.css';
import { useAuth } from '../AuthContext';
import PostVote from '../components/PostVote';
import { formatDistanceToNow } from 'date-fns';

const Dashboard = () => {
    const navigate = useNavigate();
    const { isAuthenticated, user } = useAuth();
    const [feedPosts, setFeedPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const defaultAvatar = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074";

    useEffect(() => {
        const fetchFeedPosts = async () => {
            if (!user) {
                console.log("No user found, skipping feed fetch");
                return;
            }
            
            try {
                setLoading(true);
                console.log("Starting feed fetch for user:", user.uid);
                
                const userCommunitiesQuery = query(
                    collection(db, 'communities'),
                    where('members', 'array-contains', user.uid)
                );
                
                const communitiesSnapshot = await getDocs(userCommunitiesQuery);
                console.log("Found communities:", communitiesSnapshot.size);
                
                if (communitiesSnapshot.empty) {
                    console.log("User is not a member of any communities");
                    setFeedPosts([]);
                    setLoading(false);
                    return;
                }

                const allPosts = [];
                
                // Fetch posts from each community
                for (const communityDoc of communitiesSnapshot.docs) {
                    const communityData = communityDoc.data();
                    const communityName = communityData.name.toLowerCase();
                    console.log("Fetching posts for community:", communityName);
                    
                    const postsRef = collection(db, 'communities', communityName, 'posts');
                    const postsQuery = query(postsRef, orderBy('createdAt', 'desc'));
                    const postsSnapshot = await getDocs(postsQuery);
                    
                    console.log(`Found ${postsSnapshot.size} posts in ${communityData.name}`);
                    
                    // Fetch author data for each post
                    const communityPosts = await Promise.all(postsSnapshot.docs.map(async (postDoc) => {
                        const postData = postDoc.data();
                        let authorData = {
                            username: '[deleted]',
                            profileImageUrl: defaultAvatar
                        };

                        // Fetch author data if authorId exists
                        if (postData.authorId) {
                            const authorDoc = await getDoc(doc(db, 'users', postData.authorId));
                            if (authorDoc.exists()) {
                                authorData = authorDoc.data().userProfile;
                            }
                        }

                        // Fetch comment count
                        const commentsSnapshot = await getDocs(
                            collection(db, 'communities', communityName, 'posts', postDoc.id, 'comments')
                        );

                        // Create the slug from the post title if it doesn't exist
                        const slug = postData.slug || postData.title.toLowerCase()
                            .replace(/[^a-z0-9]+/g, '-')
                            .replace(/(^-|-$)/g, '');

                        return {
                            id: postDoc.id,
                            communityId: communityDoc.id,
                            communityName: communityData.name,
                            authorUsername: authorData.username,
                            authorProfilePhoto: authorData.profileImageUrl || defaultAvatar,
                            ...postData,
                            slug: slug,
                            voteCount: postData.voteCount || 0,
                            commentCount: commentsSnapshot.size,
                            createdAt: postData.createdAt?.toDate() || new Date()
                        };
                    }));
                    
                    allPosts.push(...communityPosts);
                }

                console.log("Total posts found:", allPosts.length);
                setFeedPosts(allPosts);
                
            } catch (error) {
                console.error('Error fetching feed:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchFeedPosts();
    }, [user]);

    if (!isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="page-header">
                        <h1>Your Feed</h1>
                    </div>
                    <div className="feed-container">
                        {loading ? (
                            <div className="loading-spinner"></div>
                        ) : feedPosts.length > 0 ? (
                            feedPosts.map(post => (
                                <FeedPost key={post.id} post={post} setFeedPosts={setFeedPosts} />
                            ))
                        ) : (
                            <div className="empty-feed">
                                <p>Your feed is empty! Join some communities to see posts here.</p>
                                <Link to="/communities" className="button-primary">
                                    Browse Communities
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

const FeedPost = ({ post, setFeedPosts }) => {
    return (
        <div className="feed-post">
            <div className="post-header">
                <Link to={`/user/${post.authorUsername}`} className="profile-photo-link">
                    <img src={post.authorProfilePhoto} alt="Profile" className="profile-photo" />
                </Link>
                <div className="post-meta">
                    <Link to={`/user/${post.authorUsername}`} className="username-link">
                        {post.authorUsername}
                    </Link>
                    <span className="post-meta-separator">•</span>
                    <Link to={`/c/${post.communityName}`} className="feed-community-link">
                        r/{post.communityName}
                    </Link>
                    <span className="post-meta-separator">•</span>
                    <span className="post-date">
                        {formatDistanceToNow(post.createdAt)} ago
                    </span>
                </div>
            </div>
            <Link 
                to={`/c/${post.communityName}/posts/${post.id}/${post.slug}`} 
                className="post-content-link"
            >
                <h3 className="post-title">{post.title}</h3>
                <div className="post-text">
                    {post.isHtml ? (
                        <div dangerouslySetInnerHTML={{ __html: post.content }} />
                    ) : (
                        post.content
                    )}
                </div>
            </Link>
            <div className="post-actions">
                <div className="vote-section">
                    <PostVote 
                        postId={post.id}
                        communityName={post.communityName}
                        initialVoteCount={post.voteCount || 0}
                        onVoteChange={(newCount) => {
                            // Update the vote count in the feed posts
                            setFeedPosts(prevPosts => 
                                prevPosts.map(p => 
                                    p.id === post.id 
                                        ? { ...p, voteCount: newCount }
                                        : p
                                )
                            );
                        }}
                    />
                </div>
                <Link 
                    to={`/c/${post.communityName}/posts/${post.id}/${post.slug}`} 
                    className="action-button"
                >
                    <i className="far fa-comment"></i>
                    {post.commentCount ? `${post.commentCount} Comments` : '0 Comments'}
                </Link>
            </div>
        </div>
    );
};

export default Dashboard; 