import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc, getDocs, query, where, orderBy, limit, startAfter } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './Clubs.css';

const Clubs = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clubAccountId, setClubAccountId] = useState('');
    const [clubData, setClubData] = useState({
        clubName: '',
        city: '',
        state: '',
        phone: '',
        website: '',
        email: '',
        weapons: {
            foil: false,
            epee: false,
            saber: false
        },
        clubOwner: '',
        location: '',
        division: '',
        facebookPage: '',
        twitterPage: '',
        instagramPage: '',
        coaches: ''
    });
    const [error, setError] = useState('');
    const [isIdTaken, setIsIdTaken] = useState(false);
    const [clubs, setClubs] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user, isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const CLUBS_PER_PAGE = 10;

    const defaultUserPhoto = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074";
    const defaultClubPhoto = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultclub%2Fclub_default.png?alt=media&token=be631b3a-b250-45f0-a683-c1760dd9d96a"; 

    // Fetch clubs (similar to communities fetch)
    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const q = query(
                    collection(db, 'clubs'),
                    orderBy('createdAt', 'desc'),
                    limit(CLUBS_PER_PAGE)
                );
                const querySnapshot = await getDocs(q);
                const clubsList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                
                setClubs(clubsList);
                setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
                setHasMore(querySnapshot.docs.length === CLUBS_PER_PAGE);
            } catch (error) {
                setError('Error loading clubs: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchClubs();
    }, []);

    // Validate club account ID format
    const validateClubId = (id) => {
        return /^[a-zA-Z0-9_-]*$/.test(id);
    };

    // Check if club ID is available
    const checkClubId = async (id) => {
        if (!id) return;

        try {
            const q = query(
                collection(db, 'clubs'),
                where('accountIdLower', '==', id.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                setError(`"${id}" is already taken`);
                setIsIdTaken(true);
            } else {
                setError('');
                setIsIdTaken(false);
            }
        } catch (err) {
            console.error('Error checking club ID:', err);
        }
    };

    // Handle club ID input change
    const handleClubIdChange = (e) => {
        const value = e.target.value.trim();
        
        if (!validateClubId(value)) {
            setError('Club IDs must only contain letters, numbers, underscores, or hyphens');
            return;
        }

        setClubAccountId(value);
        setError('');
    };

    // Handle club ID blur (when user clicks away)
    const handleClubIdBlur = () => {
        if (clubAccountId) {
            checkClubId(clubAccountId);
        }
    };

    // Handle input changes for club data
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setClubData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle weapon checkbox changes
    const handleWeaponChange = (weapon) => {
        setClubData(prev => ({
            ...prev,
            weapons: {
                ...prev.weapons,
                [weapon]: !prev.weapons[weapon]
            }
        }));
    };

    const handleCreateClub = async (e) => {
        e.preventDefault();
        
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        if (isIdTaken) {
            return;
        }

        try {
            console.log('Using default club photo:', defaultClubPhoto);
            
            await addDoc(collection(db, 'clubs'), {
                accountId: clubAccountId,
                accountIdLower: clubAccountId.toLowerCase(),
                ...clubData,
                createdBy: user.uid,
                clubAdmin: user.uid,
                createdAt: new Date(),
                profileImageUrl: defaultClubPhoto
            });

            navigate(`/club/${clubAccountId}`);
        } catch (error) {
            setError('Error creating club: ' + error.message);
        }
    };

    // Add these new functions for pagination
    const loadNextPage = async () => {
        if (!lastDoc || !hasMore) return;
        
        setLoading(true);
        try {
            const q = query(
                collection(db, 'clubs'),
                orderBy('createdAt', 'desc'),
                startAfter(lastDoc),
                limit(CLUBS_PER_PAGE)
            );
            const querySnapshot = await getDocs(q);
            const newClubs = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            setClubs(newClubs);
            setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setHasMore(querySnapshot.docs.length === CLUBS_PER_PAGE);
            setCurrentPage(prev => prev + 1);
        } catch (error) {
            setError('Error loading more clubs: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    const loadPreviousPage = async () => {
        if (currentPage === 1) return;
        
        setLoading(true);
        try {
            const q = query(
                collection(db, 'clubs'),
                orderBy('createdAt', 'desc'),
                limit(CLUBS_PER_PAGE * (currentPage - 1))
            );
            const querySnapshot = await getDocs(q);
            const allClubs = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            const previousPageClubs = allClubs.slice(-CLUBS_PER_PAGE);
            setClubs(previousPageClubs);
            setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setCurrentPage(prev => prev - 1);
            setHasMore(true);
        } catch (error) {
            setError('Error loading previous page: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="page-header">
                        <h1>Clubs</h1>
                        <button 
                            className="button-primary"
                            onClick={() => setIsModalOpen(true)}
                        >
                            Create a club
                        </button>
                    </div>

                    {/* Clubs List */}
                    {loading ? (
                        <div className="loading-container">
                            <div className="loading-spinner"></div>
                            <div>Loading clubs...</div>
                        </div>
                    ) : (
                        <div className="clubs-list">
                            {clubs.map(club => (
                                <div key={club.id} className="club-card">
                                    <Link to={`/club/${club.accountId}`} className="club-link">
                                        <div className="club-card-content">
                                            <img 
                                                src={club.profileImageUrl || defaultClubPhoto}
                                                alt={club.clubName}
                                                className="club-card-avatar"
                                            />
                                            <div className="club-card-info">
                                                <h2>{club.clubName}</h2>
                                                <p>{club.city}, {club.state}</p>
                                                <div className="weapons-list">
                                                    {club.weapons.foil && <span className="weapon-tag">Foil</span>}
                                                    {club.weapons.epee && <span className="weapon-tag">Epee</span>}
                                                    {club.weapons.saber && <span className="weapon-tag">Saber</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Create Club Modal */}
                    {isModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <h2>Create your club</h2>
                                <p>Provide details about your club so others can find it.</p>
                                
                                <form onSubmit={handleCreateClub}>
                                    <div className="form-group">
                                        <label>Club Account ID</label>
                                        <div className="input-prefix-container">
                                            <span className="input-prefix">club/</span>
                                            <input
                                                type="text"
                                                value={clubAccountId}
                                                onChange={handleClubIdChange}
                                                onBlur={handleClubIdBlur}
                                                required
                                                maxLength={30}
                                                className={error ? 'error' : ''}
                                            />
                                        </div>
                                        {error && <p className="error-message">{error}</p>}
                                    </div>

                                    <div className="form-group">
                                        <label>Club Name</label>
                                        <input
                                            type="text"
                                            name="clubName"
                                            value={clubData.clubName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>City</label>
                                            <input
                                                type="text"
                                                name="city"
                                                value={clubData.city}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>State</label>
                                            <input
                                                type="text"
                                                name="state"
                                                value={clubData.state}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Weapons</label>
                                        <div className="weapons-checkboxes">
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={clubData.weapons.foil}
                                                    onChange={() => handleWeaponChange('foil')}
                                                /> Foil
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={clubData.weapons.epee}
                                                    onChange={() => handleWeaponChange('epee')}
                                                /> Epee
                                            </label>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={clubData.weapons.saber}
                                                    onChange={() => handleWeaponChange('saber')}
                                                /> Saber
                                            </label>
                                        </div>
                                    </div>

                                    {/* Additional fields */}
                                    <div className="form-group">
                                        <label>Club Owner</label>
                                        <input
                                            type="text"
                                            name="clubOwner"
                                            value={clubData.clubOwner}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label>Location (Address)</label>
                                        <input
                                            type="text"
                                            name="location"
                                            value={clubData.location}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="contact-info-section">
                                        <h3>Contact Information</h3>
                                        <div className="form-group">
                                            <label>Phone</label>
                                            <input
                                                type="tel"
                                                name="phone"
                                                value={clubData.phone}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input
                                                type="email"
                                                name="email"
                                                value={clubData.email}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Website</label>
                                            <input
                                                type="url"
                                                name="website"
                                                value={clubData.website}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="social-media-section">
                                        <h3>Social Media</h3>
                                        <div className="form-group">
                                            <label>Facebook Page</label>
                                            <input
                                                type="text"
                                                name="facebookPage"
                                                value={clubData.facebookPage}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Twitter Page</label>
                                            <input
                                                type="text"
                                                name="twitterPage"
                                                value={clubData.twitterPage}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Instagram Page</label>
                                            <input
                                                type="text"
                                                name="instagramPage"
                                                value={clubData.instagramPage}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label>Coaches</label>
                                        <textarea
                                            name="coaches"
                                            value={clubData.coaches}
                                            onChange={handleInputChange}
                                            placeholder="List the club's coaches"
                                            rows={4}
                                        />
                                    </div>

                                    <div className="modal-buttons">
                                        <button 
                                            type="button" 
                                            onClick={() => setIsModalOpen(false)}
                                            className="cancel-button"
                                        >
                                            Cancel
                                        </button>
                                        <button 
                                            type="submit"
                                            className="create-button"
                                            disabled={!clubAccountId || isIdTaken || !!error}
                                        >
                                            Create Club
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {/* Add pagination controls */}
                    {!loading && clubs.length > 0 && (
                        <div className="pagination-controls">
                            <button 
                                onClick={loadPreviousPage}
                                disabled={currentPage === 1}
                                className="pagination-button"
                            >
                                Previous Page
                            </button>
                            <span className="page-indicator">Page {currentPage}</span>
                            <button 
                                onClick={loadNextPage}
                                disabled={!hasMore}
                                className="pagination-button"
                            >
                                Next Page
                            </button>
                        </div>
                    )}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Clubs;
