import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth } from './firebase';
import { AuthProvider, useAuth } from './AuthContext';
import SearchResults from './Auth/SearchResults';

// Auth pages
import Dashboard from './Auth/Dashboard';
import AccountSettings from './Auth/Settings/AccountSettings';
import ProfileSettings from './Auth/Settings/ProfileSettings';

// UnAuth pages
import HomePage from './unAuth/HomePage';
import About from './unAuth/About';
import TermsOfService from './unAuth/TermsOfService';
import PrivacyPolicy from './unAuth/PrivacyPolicy';
import LoginPage from './unAuth/LoginPage';
import SignUpPage from './unAuth/SignUpPage';   
import SetupUsernamePassword from './Auth/SetupUsernamePassword';
import UserProfile from './unAuth/UserProfile';
import Inbox from './Auth/Inbox';
import RequireAuth from './components/RequireAuth';
import FollowersPage from './unAuth/FollowersPage';
import FollowingPage from './unAuth/FollowingPage';
import Community from './unAuth/Community';
import CommunityPage from './unAuth/CommunityPage';
import CreatePostPage from './unAuth/CreatePostPage';
import PostPage from './unAuth/PostPage';
import ContactPage from './unAuth/ContactPage';
import Clubs from './unAuth/Clubs';
import ClubPage from './unAuth/ClubPage';
import ClubEdit from './unAuth/ClubEdit';
import CommunityEdit from './unAuth/CommunityEdit';
import ForgotPassword from './unAuth/ForgotPassword';
import ResetPassword from './unAuth/ResetPassword';


// PrivateRoute component
const PrivateRoute = ({ element }) => {
    const { isAuthenticated, loading } = useAuth();
    
    console.log('PrivateRoute render:', { isAuthenticated, loading, path: window.location.pathname });
    
    // Don't show loading state for authenticated users
    if (loading && !isAuthenticated) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? element : <Navigate to="/login" replace />;
}

// Create a ScrollToTop component
function ScrollToTop() {
    const location = useLocation();
    
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    
    return null;
}

function App() {
    return (
        <AuthProvider>
            <AppContent />
        </AuthProvider>
    );
}

function AppContent() {
    const { loading, isAuthenticated, isPasswordSet } = useAuth();
    const location = useLocation();

    // Show loading state only during initial auth check
    if (loading && !isAuthenticated) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <ScrollToTop />
            <Routes>
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/" element={
                    isAuthenticated && isPasswordSet ? (
                        <Navigate to="/dashboard" replace />
                    ) : (
                        <HomePage />
                    )
                } />
                <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
                <Route path="/terms" element={<TermsOfService />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/about" element={<About />} />
                <Route path="/settings/profile" element={<PrivateRoute element={<ProfileSettings />} />} />
                <Route path="/settings/account" element={<PrivateRoute element={<AccountSettings />} />} />
                <Route path="/setup" element={<PrivateRoute element={<SetupUsernamePassword />} />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/signup" element={<SignUpPage />} />
                <Route path="/user/:username" element={<UserProfile />} />
                <Route path="/user/:username/followers" element={<FollowersPage />} />
                <Route path="/user/:username/following" element={<FollowingPage />} />
                <Route path="/search" element={<PrivateRoute element={<SearchResults />} />} />
                <Route path="/inbox" element={<RequireAuth><Inbox /></RequireAuth>} />
                <Route path="/followers" element={<FollowersPage />} />
                <Route path="/following" element={<FollowingPage />} />
                <Route path="/followers/:username" element={<FollowersPage />} />
                <Route path="/following/:username" element={<FollowingPage />} />
                <Route path="/communities" element={<Community />} />
                <Route path="/c/:communityName" element={<CommunityPage />} />
                <Route path="/c/:communityName/post" element={<CreatePostPage />} />
                <Route path="/c/:communityName/posts/:postId/:postSlug" element={<PostPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route 
                    path="/c/:communityName/comments/:postId/:postSlug" 
                    element={<PostPage />} 
                />
                <Route path="/clubs" element={<Clubs />} />
                <Route path="/club/:clubId" element={<ClubPage />} />
                <Route path="/club/:clubId/edit" element={<ClubEdit />} />
                <Route path="/c/:communityName/edit" element={<CommunityEdit />} />
                <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
        </>
    );
}

export default App;
