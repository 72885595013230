import { signInWithPopup, sendSignInLinkToEmail } from 'firebase/auth';
import { auth, googleProvider } from './firebase';
import { FacebookAuthProvider } from 'firebase/auth';

// Google Sign In
export const signInWithGoogle = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        return result.user;
    } catch (error) {
        throw error;
    }
};

// Passwordless Email Sign In
export const sendEmailLink = async (email) => {
    const actionCodeSettings = {
        url: window.location.origin, // or your specific redirect URL
        handleCodeInApp: true
    };

    try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem('emailForSignIn', email);
    } catch (error) {
        throw error;
    }
};

export const signInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    try {
        const result = await signInWithPopup(auth, provider);
        return result.user;
    } catch (error) {
        throw error;
    }
};
