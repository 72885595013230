import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { 
    doc, 
    getDoc, 
    updateDoc,
    collection,
    getDocs,
    query,
    where
} from 'firebase/firestore';
import { db } from '../../firebase';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import './ProfileSettings.css';
import { Link } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase';
import Alert from '../../components/Alert';
import '../../styles/SharedStyles.css';
import { DEFAULT_PROFILE_PHOTO } from '../../utils/constants';

const ProfileSettings = () => {
    const { user } = useAuth();
    const [username, setUsername] = useState('');
    const [profileData, setProfileData] = useState({
        username: '',
        firstName: '',
        middleName: '',
        lastName: '',
        location: '',
        yearOfBirth: '',
        gender: '',
        height: '',
        weight: '',
        handedness: '',
        instagramAccount: '',
        twitterAccount: '',
        primaryClub: '',
        secondaryClub: '',
        coach: '',
        occupation: '',
        education: '',
        milestones: '',
        biography: '',
        isFencer: false,
        isCoach: false,
        fencingTrackerUrl: '',
        videos: []
    });
    const [isEditing, setIsEditing] = useState({
        username: false,
        firstName: false,
        middleName: false,
        lastName: false,
        location: false,
        yearOfBirth: false,
        gender: false,
        height: false,
        weight: false,
        handedness: false,
        instagramAccount: false,
        twitterAccount: false,
        primaryClub: false,
        secondaryClub: false,
        coach: false,
        occupation: false,
        education: false,
        milestones: false,
        biography: false,
        membership: false,
        fencingTrackerUrl: false
    });
    const [newValue, setNewValue] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [followerCount, setFollowerCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);

    useEffect(() => {
        if (user) {
            const fetchProfileData = async () => {
                try {
                    const docRef = doc(db, 'users', user.uid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists() && docSnap.data().userProfile) {
                        const profile = docSnap.data().userProfile;
                        setUsername(profile.username || '');
                        setProfileData({
                            username: profile.username || '',
                            firstName: profile.firstName || '',
                            middleName: profile.middleName || '',
                            lastName: profile.lastName || '',
                            location: profile.location || '',
                            yearOfBirth: profile.yearOfBirth || '',
                            gender: profile.gender || '',
                            height: profile.height || '',
                            weight: profile.weight || '',
                            handedness: profile.handedness || '',
                            instagramAccount: profile.instagramAccount || '',
                            twitterAccount: profile.twitterAccount || '',
                            primaryClub: profile.primaryClub || '',
                            secondaryClub: profile.secondaryClub || '',
                            coach: profile.coach || '',
                            occupation: profile.occupation || '',
                            education: profile.education || '',
                            milestones: profile.milestones || '',
                            biography: profile.biography || '',
                            isFencer: profile.isFencer || false,
                            isCoach: profile.isCoach || false,
                            fencingTrackerUrl: profile.fencingTrackerUrl || '',
                            videos: profile.videos || []
                        });
                        if (profile.profileImageUrl) {
                            setProfileImageUrl(profile.profileImageUrl);
                        }
                    }
                } catch (err) {
                    console.error('Error fetching profile:', err);
                }
            };
            fetchProfileData();
        }
    }, [user]);

    useEffect(() => {
        const fetchFollowCounts = async () => {
            if (user) {
                try {
                    // Get followers count
                    const followersQuery = query(
                        collection(db, 'follows'),
                        where('followingId', '==', user.uid)
                    );
                    const followersSnapshot = await getDocs(followersQuery);
                    setFollowerCount(followersSnapshot.size);

                    // Get following count
                    const followingQuery = query(
                        collection(db, 'follows'),
                        where('followerId', '==', user.uid)
                    );
                    const followingSnapshot = await getDocs(followingQuery);
                    setFollowingCount(followingSnapshot.size);
                } catch (error) {
                    console.error('Error fetching follow counts:', error);
                }
            }
        };

        fetchFollowCounts();
    }, [user]);

    const handleEditClick = (field) => {
        if (field === 'membership') {
            setIsEditing({ ...isEditing, [field]: true });
            setError('');
        } else {
            setIsEditing({ ...isEditing, [field]: true });
            setNewValue(profileData[field]);
            setError('');
        }
    };

    const handleSave = async (field) => {
        if (!newValue.trim() && (field === 'firstName' || field === 'username')) {
            setError(`${field === 'firstName' ? 'First name' : 'Username'} cannot be empty`);
            return;
        }

        setLoading(true);
        setError('');

        try {
            const userRef = doc(db, 'users', user.uid);
            const docSnap = await getDoc(userRef);
            
            // If updating username, check if it's available
            if (field === 'username') {
                const usersRef = collection(db, 'users');
                const querySnapshot = await getDocs(usersRef);
                
                // Check if username exists
                let isAvailable = true;
                querySnapshot.forEach((doc) => {
                    const userData = doc.data();
                    if (userData.userProfile && 
                        userData.userProfile.username === newValue.trim() && 
                        doc.id !== user.uid) {
                        isAvailable = false;
                    }
                });

                if (!isAvailable) {
                    setError('Username is already taken');
                    setLoading(false);
                    return;
                }
            }
            
            if (!docSnap.exists()) {
                const newProfile = {
                    userProfile: {
                        [field]: newValue.trim()
                    }
                };
                await updateDoc(userRef, newProfile);
            } else {
                await updateDoc(userRef, {
                    [`userProfile.${field}`]: newValue.trim()
                });
            }

            setProfileData({ ...profileData, [field]: newValue.trim() });
            // Update username state if that's what changed
            if (field === 'username') {
                setUsername(newValue.trim());
            }
            setIsEditing({ ...isEditing, [field]: false });
        } catch (err) {
            console.error('Error updating profile:', err);
            setError(`An error occurred while updating ${field}`);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = (field) => {
        setIsEditing({ ...isEditing, [field]: false });
        setError('');
    };

    const renderEditModal = (field, label) => {
        if (!isEditing[field]) return null;

        if (field === 'membership') {
            return (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Membership</h2>
                        <p>Select your membership type:</p>
                        <div className="checkbox-group">
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={profileData.isFencer}
                                    onChange={(e) => setProfileData(prev => ({
                                        ...prev,
                                        isFencer: e.target.checked
                                    }))}
                                    disabled={loading}
                                />
                                Fencer
                            </label>
                            <label className="checkbox-label">
                                <input
                                    type="checkbox"
                                    checked={profileData.isCoach}
                                    onChange={(e) => setProfileData(prev => ({
                                        ...prev,
                                        isCoach: e.target.checked
                                    }))}
                                    disabled={loading}
                                />
                                Coach
                            </label>
                        </div>
                        <div className="modal-buttons">
                            <button 
                                onClick={handleSaveMembership}
                                disabled={loading}
                            >
                                {loading ? 'Saving...' : 'Save'}
                            </button>
                            <button 
                                onClick={() => handleCancel('membership')}
                                disabled={loading}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <h2>{label}</h2>
                    <p>Enter your {label.toLowerCase()}</p>
                    {field === 'biography' ? (
                        <textarea
                            value={newValue}
                            onChange={(e) => setNewValue(e.target.value)}
                            disabled={loading}
                            placeholder={getPlaceholder(field)}
                            rows={6}
                            className="biography-input"
                        />
                    ) : field === 'gender' ? (
                        <select
                            value={newValue}
                            onChange={(e) => setNewValue(e.target.value)}
                            disabled={loading}
                        >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    ) : field === 'handedness' ? (
                        <select
                            value={newValue}
                            onChange={(e) => setNewValue(e.target.value)}
                            disabled={loading}
                        >
                            <option value="">Select Handedness</option>
                            <option value="Left">Left</option>
                            <option value="Right">Right</option>
                        </select>
                    ) : (
                        <input
                            type={field === 'yearOfBirth' ? 'number' : 'text'}
                            value={newValue}
                            onChange={(e) => setNewValue(e.target.value)}
                            disabled={loading}
                            placeholder={getPlaceholder(field)}
                        />
                    )}
                    {error && <p className="error-message">{error}</p>}
                    <div className="modal-buttons">
                        <button 
                            onClick={() => handleSave(field)}
                            disabled={loading}
                        >
                            {loading ? 'Saving...' : 'Save'}
                        </button>
                        <button 
                            onClick={() => handleCancel(field)}
                            disabled={loading}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // Validate file type
        if (!file.type.startsWith('image/')) {
            alert('Please upload an image file');
            return;
        }

        // Validate file size (e.g., max 5MB)
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        if (file.size > maxSize) {
            alert('File is too large. Maximum size is 5MB');
            return;
        }

        try {
            setIsUploading(true);
            setError('');
            
            // Create the storage reference
            const storageRef = ref(storage, `profileImages/${user.uid}/profile.jpg`);
            
            // Upload the file
            const snapshot = await uploadBytes(storageRef, file);
            console.log('Uploaded file successfully:', snapshot);
            
            // Get the download URL
            const url = await getDownloadURL(storageRef);
            console.log('Got download URL:', url);
            
            // Update the user's profile in Firestore
            const userRef = doc(db, 'users', user.uid);
            await updateDoc(userRef, {
                'userProfile.profileImageUrl': url
            });
            
            // Update the UI
            setProfileImageUrl(url);
            setAlertMessage('Profile image updated successfully!');
            
            // Automatically clear the alert after 3 seconds
            setTimeout(() => {
                setAlertMessage('');
            }, 3000);
            
        } catch (error) {
            console.error('Error uploading image:', error);
            setError('Failed to upload image: ' + error.message);
            setAlertMessage('Failed to upload image. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleDeleteProfileImage = async () => {
        if (window.confirm('Are you sure you want to remove your profile photo?')) {
            try {
                setIsUploading(true);
                setError('');
                
                // Update Firestore to remove the profileImageUrl
                const userRef = doc(db, 'users', user.uid);
                await updateDoc(userRef, {
                    'userProfile.profileImageUrl': null
                });
                
                // Update the UI
                setProfileImageUrl('');
                setAlertMessage('Profile image removed successfully!');
                
                // Automatically clear the alert after 3 seconds
                setTimeout(() => {
                    setAlertMessage('');
                }, 3000);
                
            } catch (error) {
                console.error('Error removing profile image:', error);
                setError('Failed to remove image: ' + error.message);
                setAlertMessage('Failed to remove image. Please try again.');
            } finally {
                setIsUploading(false);
            }
        }
    };

    const hasCustomPhoto = profileImageUrl && profileImageUrl !== DEFAULT_PROFILE_PHOTO;

    const handleSaveMembership = async () => {
        setLoading(true);
        setError('');

        try {
            const userRef = doc(db, 'users', user.uid);
            await updateDoc(userRef, {
                'userProfile.isFencer': profileData.isFencer,
                'userProfile.isCoach': profileData.isCoach
            });
            
            setIsEditing({ ...isEditing, membership: false });
        } catch (err) {
            console.error('Error updating membership:', err);
            setError('An error occurred while updating membership');
        } finally {
            setLoading(false);
        }
    };

    const handleAddVideo = () => {
        if (profileData.videos.length >= 20) {
            setError('Maximum of 20 videos allowed');
            return;
        }
        setProfileData(prev => ({
            ...prev,
            videos: [...prev.videos, '']
        }));
    };

    const handleRemoveVideo = (index) => {
        setProfileData(prev => ({
            ...prev,
            videos: prev.videos.filter((_, i) => i !== index)
        }));
    };

    const handleVideoChange = async (index, value) => {
        const newVideos = [...profileData.videos];
        newVideos[index] = value;
        
        try {
            setLoading(true);
            const userRef = doc(db, 'users', user.uid);
            await updateDoc(userRef, {
                'userProfile.videos': newVideos
            });
            
            setProfileData(prev => ({
                ...prev,
                videos: newVideos
            }));
            setAlertMessage('Videos updated successfully!');
        } catch (err) {
            console.error('Error updating videos:', err);
            setError('Failed to update videos');
        } finally {
            setLoading(false);
        }
    };

    const getYoutubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    return (
        <>
            <Header />
            {alertMessage && (
                <Alert 
                    message={alertMessage} 
                    type="success" 
                    onClose={() => setAlertMessage('')}
                />
            )}
            <main>
                <div className="page-container">
                    <div className="page-header">
                        <h1>My Profile Settings</h1>
                        {username && (
                            <Link to={`/user/${username}`} className="button-secondary">
                                View Profile
                            </Link>
                        )}
                    </div>
                    
                    <div className="profile-layout">
                        {/* Left Column - Profile Image */}
                        <div className="profile-sidebar">
                            <div className="profile-image-container">
                                <img 
                                    src={profileImageUrl || 'https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074'} 
                                    alt="Profile" 
                                    className="profile-image"
                                />
                            </div>
                            <div className="profile-image-actions">
                                <label className="button-secondary">
                                    Upload Image
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        style={{ display: 'none' }}
                                        disabled={isUploading}
                                    />
                                </label>
                                {hasCustomPhoto && (
                                    <button
                                        className="button-tertiary"
                                        onClick={handleDeleteProfileImage}
                                        disabled={isUploading}
                                    >
                                        Remove Photo
                                    </button>
                                )}
                            </div>
                            <div className="follow-stats">
                                <Link to={`/user/${username}/following`} className="follow-stat">
                                    <span className="follow-count">{followingCount}</span>
                                    <span className="follow-label">Following</span>
                                </Link>
                                <Link to={`/user/${username}/followers`} className="follow-stat">
                                    <span className="follow-count">{followerCount}</span>
                                    <span className="follow-label">Followers</span>
                                </Link>
                            </div>
                        </div>

                        {/* Right Column - Profile Information */}
                        <div className="profile-content">
                            <h2>Personal Information</h2>
                            <div className="settings-grid">
                                <div className="field-row">
                                    <span className="field-label">First Name:</span>
                                    <span className="field-value">{profileData.firstName || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('firstName')}
                                        className="edit-button"
                                        aria-label="Edit first name"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Middle Name:</span>
                                    <span className="field-value">{profileData.middleName || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('middleName')}
                                        className="edit-button"
                                        aria-label="Edit middle name"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Last Name:</span>
                                    <span className="field-value">{profileData.lastName || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('lastName')}
                                        className="edit-button"
                                        aria-label="Edit last name"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Username:</span>
                                    <span className="field-value">@{profileData.username || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('username')}
                                        className="edit-button"
                                        aria-label="Edit username"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Location:</span>
                                    <span className="field-value">{profileData.location || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('location')}
                                        className="edit-button"
                                        aria-label="Edit location"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Year of Birth:</span>
                                    <span className="field-value">{profileData.yearOfBirth || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('yearOfBirth')}
                                        className="edit-button"
                                        aria-label="Edit year of birth"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Gender:</span>
                                    <span className="field-value">{profileData.gender || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('gender')}
                                        className="edit-button"
                                        aria-label="Edit gender"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Height:</span>
                                    <span className="field-value">{profileData.height || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('height')}
                                        className="edit-button"
                                        aria-label="Edit height"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Weight:</span>
                                    <span className="field-value">{profileData.weight || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('weight')}
                                        className="edit-button"
                                        aria-label="Edit weight"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Handedness:</span>
                                    <span className="field-value">{profileData.handedness || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('handedness')}
                                        className="edit-button"
                                        aria-label="Edit handedness"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </div>

                            {/* Add these modal renders */}
                            {renderEditModal('firstName', 'First Name')}
                            {renderEditModal('middleName', 'Middle Name')}
                            {renderEditModal('lastName', 'Last Name')}
                            {renderEditModal('username', 'Username')}
                            {renderEditModal('location', 'Location')}
                            {renderEditModal('yearOfBirth', 'Year of Birth')}
                            {renderEditModal('gender', 'Gender')}
                            {renderEditModal('height', 'Height')}
                            {renderEditModal('weight', 'Weight')}
                            {renderEditModal('handedness', 'Handedness')}
                            {renderEditModal('membership', 'Membership')}
                            {renderEditModal('instagramAccount', 'Instagram')}
                            {renderEditModal('twitterAccount', 'Twitter')}
                            {renderEditModal('primaryClub', 'Primary Club')}
                            {renderEditModal('secondaryClub', 'Secondary Club')}
                            {renderEditModal('coach', 'Coach')}
                            {renderEditModal('occupation', 'Occupation')}
                            {renderEditModal('education', 'Education')}
                            {renderEditModal('milestones', 'Milestones')}
                            {renderEditModal('biography', 'Biography')}
                            {renderEditModal('fencingTrackerUrl', 'FencingTracker URL')}

                            <h2>Social Media</h2>
                            <div className="settings-grid">
                                <div className="field-row">
                                    <span className="field-label">Instagram:</span>
                                    <span className="field-value">{profileData.instagramAccount || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('instagramAccount')}
                                        className="edit-button"
                                        aria-label="Edit Instagram account"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Twitter:</span>
                                    <span className="field-value">{profileData.twitterAccount || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('twitterAccount')}
                                        className="edit-button"
                                        aria-label="Edit Twitter account"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </div>

                            <h2>Club Information</h2>
                            <div className="settings-grid">
                                <div className="field-row">
                                    <span className="field-label">Membership:</span>
                                    <span className="field-value membership-tags">
                                        {!profileData.isFencer && !profileData.isCoach ? (
                                            'Not set'
                                        ) : (
                                            <>
                                                {profileData.isFencer && <span className="role-tag">Fencer</span>}
                                                {profileData.isCoach && <span className="role-tag">Coach</span>}
                                            </>
                                        )}
                                    </span>
                                    <button 
                                        onClick={() => handleEditClick('membership')}
                                        className="button-icon"
                                        aria-label="Edit membership"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Coach:</span>
                                    <span className="field-value">{profileData.coach || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('coach')}
                                        className="edit-button"
                                        aria-label="Edit coach"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Primary Club:</span>
                                    <span className="field-value">{profileData.primaryClub || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('primaryClub')}
                                        className="edit-button"
                                        aria-label="Edit primary club"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Secondary Club:</span>
                                    <span className="field-value">{profileData.secondaryClub || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('secondaryClub')}
                                        className="edit-button"
                                        aria-label="Edit secondary club"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </div>

                            <h2>Bio</h2>
                            <div className="settings-grid">
                                <div className="field-row">
                                    <span className="field-label">Occupation:</span>
                                    <span className="field-value">{profileData.occupation || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('occupation')}
                                        className="edit-button"
                                        aria-label="Edit occupation"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Education:</span>
                                    <span className="field-value">{profileData.education || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('education')}
                                        className="edit-button"
                                        aria-label="Edit education"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Milestones:</span>
                                    <span className="field-value">{profileData.milestones || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('milestones')}
                                        className="edit-button"
                                        aria-label="Edit milestones"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Biography:</span>
                                    <span className="field-value">{profileData.biography || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('biography')}
                                        className="edit-button"
                                        aria-label="Edit biography"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">FencingTracker URL:</span>
                                    <span className="field-value">
                                        {profileData.fencingTrackerUrl ? (
                                            <a 
                                                href={profileData.fencingTrackerUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {profileData.fencingTrackerUrl}
                                            </a>
                                        ) : (
                                            'Not set'
                                        )}
                                    </span>
                                    <button 
                                        onClick={() => handleEditClick('fencingTrackerUrl')}
                                        className="edit-button"
                                        aria-label="Edit FencingTracker URL"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </div>

                            <h2>Videos</h2>
                            <div className="settings-grid">
                                {profileData.videos.map((video, index) => (
                                    <div key={index} className="video-input-row">
                                        <input
                                            type="text"
                                            value={video}
                                            onChange={(e) => handleVideoChange(index, e.target.value)}
                                            placeholder="Enter YouTube video URL"
                                            className="video-input"
                                        />
                                        <button
                                            onClick={() => handleRemoveVideo(index)}
                                            className="remove-video-button"
                                            aria-label="Remove video"
                                        >
                                            <FaTrash />
                                        </button>
                                    </div>
                                ))}
                                {profileData.videos.length < 20 && (
                                    <button
                                        onClick={handleAddVideo}
                                        className="add-video-button"
                                    >
                                        <FaPlus /> Add Video
                                    </button>
                                )}
                                {error && <p className="error-message">{error}</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

const getPlaceholder = (field) => {
    switch (field) {
        case 'yearOfBirth':
            return 'Enter year (e.g., 1985)';
        case 'height':
            return 'Enter height (e.g., 5\'10" or 178cm)';
        case 'weight':
            return 'Enter weight (e.g., 150lbs or 68kg)';
        case 'handedness':
            return 'Left or Right';
        case 'instagramAccount':
            return '@username';
        case 'twitterAccount':
            return '@username';
        case 'primaryClub':
        case 'secondaryClub':
            return 'Enter club name';
        default:
            return '';
    }
};

export default ProfileSettings;
