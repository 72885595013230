import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../AuthContext';
import RichTextEditor from '../RichTextEditor';
import './CommentForm.css';

const CommentForm = ({ postId, communityName, parentId, onCommentAdded, onCancel, isReply = false }) => {
    const [content, setContent] = useState('');
    const { user, isAuthenticated } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isAuthenticated || !content.trim()) return;

        try {
            const commentData = {
                content: content,
                authorId: user.uid,
                createdAt: new Date(),
                parentId: parentId || null,
                isHtml: true
            };

            const commentsRef = collection(db, 'communities', communityName, 'posts', postId, 'comments');
            const newCommentRef = await addDoc(commentsRef, commentData);

            if (onCommentAdded) {
                onCommentAdded({
                    id: newCommentRef.id,
                    ...commentData
                });
            }

            setContent('');
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    if (!isAuthenticated) {
        return <p>Please log in to comment.</p>;
    }

    return (
        <form onSubmit={handleSubmit} className="comment-form">
            <RichTextEditor
                value={content}
                onChange={setContent}
                placeholder={isReply ? "Write a reply..." : "What are your thoughts?"}
            />
            <div className="form-actions">
                {onCancel && (
                    <button 
                        type="button" 
                        className="cancel-button" 
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                )}
                <button 
                    type="submit" 
                    className="submit-button"
                    disabled={!content.trim()}
                >
                    {isReply ? 'Reply' : 'Comment'}
                </button>
            </div>
        </form>
    );
};

export default CommentForm;
