import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import { db, auth } from '../firebase';
import { doc, updateDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { updatePassword } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import SetupLayout from './components/SetupLayout';
import './SetupUsernamePassword.css';

const SetupUsernamePassword = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email;

    // Redirect if no email in state
    useEffect(() => {
        if (!email) {
            navigate('/signup');
        }
    }, [email, navigate]);

    // Form states
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    // Validation states
    const [usernameMessage, setUsernameMessage] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');
    const [isUsernameValid, setIsUsernameValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [sessionExpired, setSessionExpired] = useState(false);

    useEffect(() => {
        // Prevent navigation away from this page
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            e.returnValue = '';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    // Add session check on component mount
    useEffect(() => {
        const checkSession = async () => {
            try {
                // Try a simple Firebase operation to test session
                await user.reload();
            } catch (error) {
                if (error.code === 'auth/requires-recent-login') {
                    setSessionExpired(true);
                }
            }
        };
        
        if (user) {
            checkSession();
        }
    }, [user]);

    // Add a handler for starting over
    const handleStartOver = () => {
        // Clear any stored data
        localStorage.removeItem('pendingSignUpEmail');
        localStorage.removeItem('pendingSetupData');
        // Sign out user
        auth.signOut();
        // Redirect to signup
        navigate('/signup', { replace: true });
    };

    // Username validation
    const validateUsername = async () => {
        // Reset message
        setUsernameMessage('');
        
        // Basic validation
        if (username.length < 3) {
            setUsernameMessage('Username must be at least 3 characters long');
            setIsUsernameValid(false);
            return;
        }

        if (!/^[a-zA-Z0-9_-]+$/.test(username)) {
            setUsernameMessage('Username can only contain letters, numbers, underscores, and hyphens');
            setIsUsernameValid(false);
            return;
        }

        try {
            // Check if username is unique
            const q = query(
                collection(db, 'users'),
                where('userProfile.username', '==', username.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                setUsernameMessage('This username is already taken');
                setIsUsernameValid(false);
                return;
            }

            setUsernameMessage('Username is available');
            setIsUsernameValid(true);
        } catch (error) {
            console.error('Error checking username:', error);
            setUsernameMessage('Error checking username availability');
            setIsUsernameValid(false);
        }
    };

    // Password validation
    const validatePassword = () => {
        if (password.length < 8) {
            setPasswordMessage('Password must be at least 8 characters long');
            setIsPasswordValid(false);
            return;
        }

        if (password !== confirmPassword) {
            setPasswordMessage('Passwords do not match');
            setIsPasswordValid(false);
            return;
        }

        setPasswordMessage('');
        setIsPasswordValid(true);
    };

    // Update the password input fields to validate on change
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        
        // Validate both passwords
        if (confirmPassword) {
            if (newPassword !== confirmPassword) {
                setPasswordMessage('Passwords do not match');
                setIsPasswordValid(false);
            } else {
                setPasswordMessage('');
                setIsPasswordValid(true);
            }
        } else if (newPassword.length < 8) {
            setPasswordMessage('Password must be at least 8 characters long');
            setIsPasswordValid(false);
        } else {
            setPasswordMessage('');
            setIsPasswordValid(true);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        
        // Validate both passwords
        if (password !== newConfirmPassword) {
            setPasswordMessage('Passwords do not match');
            setIsPasswordValid(false);
        } else {
            setPasswordMessage('');
            setIsPasswordValid(true);
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (!isUsernameValid || !isPasswordValid) {
            setError('Please fix all errors before continuing');
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setIsLoading(true);

        try {
            // Update password
            await updatePassword(user, password);
            
            // Update user profile in Firestore and remove temporary password flag
            await updateDoc(doc(db, 'users', user.uid), {
                userProfile: {
                    username: username.toLowerCase(),
                    email: email,
                    isDefaultUsername: false,
                    createdAt: new Date()
                },
                isTemporaryPassword: false
            });

            // Navigate to dashboard
            navigate('/dashboard', { 
                state: { message: 'Account setup completed successfully!' },
                replace: true
            });

        } catch (error) {
            console.error('Error setting up account:', error);
            if (error.code === 'auth/requires-recent-login') {
                setSessionExpired(true);
            } else {
                setError(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <SetupLayout>
            <div className="setup-container">
                <div className="setup-box">
                    {sessionExpired ? (
                        <div className="session-expired">
                            <h2>Session Expired</h2>
                            <p>Your session has expired due to inactivity.</p>
                            <div className="action-buttons">
                                <button 
                                    onClick={() => navigate('/login', { 
                                        state: { 
                                            returnTo: '/setup',
                                            message: 'Please log in again to complete your account setup'
                                        }
                                    })}
                                    className="primary-button"
                                >
                                    Log In Again
                                </button>
                                <button 
                                    onClick={handleStartOver}
                                    className="secondary-button"
                                >
                                    Start Over
                                </button>
                            </div>
                        </div>
                    ) : (
                        <>
                            <h1>Complete Your Account Setup</h1>
                            <p>Choose a username and password for your account</p>

                            {error && <p className="error-message">{error}</p>}

                            <form onSubmit={handleSubmit}>
                                <div className="input-group">
                                    <label htmlFor="username">Username</label>
                                    <input
                                        id="username"
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        onBlur={validateUsername}
                                        placeholder="Choose a username"
                                        disabled={isLoading}
                                    />
                                    {usernameMessage && (
                                        <p className={isUsernameValid ? 'success-message' : 'error-message'}>
                                            {usernameMessage}
                                        </p>
                                    )}
                                </div>

                                <div className="input-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        placeholder="Create a password"
                                        disabled={isLoading}
                                    />
                                </div>

                                <div className="input-group">
                                    <label htmlFor="confirmPassword">Confirm Password</label>
                                    <input
                                        id="confirmPassword"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        placeholder="Confirm your password"
                                        disabled={isLoading}
                                    />
                                    {passwordMessage && (
                                        <p className="error-message">{passwordMessage}</p>
                                    )}
                                </div>

                                <button 
                                    type="submit"
                                    disabled={!isUsernameValid || !isPasswordValid || isLoading}
                                    className={`submit-button ${(isUsernameValid && isPasswordValid) ? 'active' : 'disabled'}`}
                                >
                                    {isLoading ? 'Creating Account...' : 'Complete Setup'}
                                </button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </SetupLayout>
    );
};

export default SetupUsernamePassword;
