import React, { useState } from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import { signInWithGoogle, signInWithFacebook } from '../googleAuth';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './LoginPage.css';
import { useAuth } from '../AuthContext';

const LoginPage = () => {
    const { isAuthenticated, loading } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (isAuthenticated) {
        return <Navigate to="/dashboard" replace />;
    }

    const handleEmailSignIn = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/dashboard');
        } catch (error) {
            console.error('Login error:', error);
            switch (error.code) {
                case 'auth/invalid-email':
                    setError('Invalid email address');
                    break;
                case 'auth/user-disabled':
                    setError('This account has been disabled');
                    break;
                case 'auth/user-not-found':
                    setError('No account found with this email');
                    break;
                case 'auth/wrong-password':
                    setError('Incorrect password');
                    break;
                default:
                    setError('Error signing in. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            await signInWithGoogle();
            navigate('/dashboard');
        } catch (error) {
            setError(error.message);
        }
    };

    const handleFacebookSignIn = async () => {
        try {
            await signInWithFacebook();
            navigate('/dashboard');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            <Header hideAuthButton={true} />
            <div className="login-page">
                <div className="login-container">
                    <h2>Login</h2>
                    {error && <p className="error">{error}</p>}
                    
                    <form onSubmit={handleEmailSignIn}>
                        <div className="form-group">
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>

                        <div className="terms-agreement">
                            <p>
                                By continuing, you agree to our{' '}
                                <Link to="/terms">Terms of Service</Link> and{' '}
                                <Link to="/privacy">Privacy Policy</Link>.
                            </p>
                        </div>

                        <button 
                            type="submit" 
                            className="email-button"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Signing in...' : 'Sign In'}
                        </button>
                    </form>

                    <div className="divider">
                        <span>OR</span>
                    </div>

                    <button 
                        className="google-button"
                        onClick={handleGoogleSignIn}
                        disabled={isLoading}
                    >
                        <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" />
                        Continue with Google
                    </button>

                    {/* Remove or comment out Facebook button
                    <button 
                        className="facebook-button"
                        onClick={handleFacebookSignIn}
                        disabled={isLoading}
                    >
                        <img src="/facebook-icon.svg" alt="Facebook" />
                        Continue with Facebook
                    </button>
                    */}

                    <div className="auth-links">
                        <Link to="/forgot-password">Forgot Password?</Link>
                        <span className="separator">•</span>
                        <Link to="/signup">Don't have an account? Sign up</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default LoginPage;
