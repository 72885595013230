import React, { useEffect, useState } from 'react';
import { useAuth } from '../../AuthContext';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { FaEdit } from 'react-icons/fa';
import './AccountSettings.css';
import { updatePassword } from 'firebase/auth';
import { auth } from '../../firebase';

const AccountSettings = () => {
    const { user } = useAuth();
    const [username, setUsername] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [newUsername, setNewUsername] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMessage, setPasswordMessage] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordLoading, setPasswordLoading] = useState(false);

    useEffect(() => {
        console.log('User:', user);
        if (user) {
            const fetchUsername = async () => {
                const docRef = doc(db, 'users', user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    console.log('User Profile:', docSnap.data().userProfile);
                    setUsername(docSnap.data().userProfile.username);
                } else {
                    console.log('No such document!');
                }
            };
            fetchUsername();
        }
    }, [user]);

    const handleEditClick = () => {
        setNewUsername(username);
        setIsEditing(true);
        setError('');
    };

    const checkUsernameAvailability = async (username) => {
        try {
            console.log('Checking username:', username);
            // Get all users
            const usersRef = collection(db, 'users');
            const querySnapshot = await getDocs(usersRef);
            
            // Check if username exists
            let isAvailable = true;
            querySnapshot.forEach((doc) => {
                const userData = doc.data();
                if (userData.userProfile && 
                    userData.userProfile.username === username && 
                    doc.id !== user.uid) {
                    isAvailable = false;
                }
            });
            
            console.log('Username available:', isAvailable);
            return isAvailable;
        } catch (error) {
            console.error('Error checking username availability:', error);
            throw error;
        }
    };

    const handleSave = async () => {
        if (!newUsername.trim()) {
            setError('Username cannot be empty');
            return;
        }

        setLoading(true);
        setError('');

        try {
            console.log('Attempting to update username for user:', user.uid);
            const userRef = doc(db, 'users', user.uid);
            
            // First, get the current document
            const docSnap = await getDoc(userRef);
            
            if (!docSnap.exists()) {
                // If document doesn't exist, create it with full structure
                await setDoc(userRef, {
                    userProfile: {
                        username: newUsername,
                        isDefaultUsername: false,
                        userEmail: user.email
                    }
                });
            } else {
                // Update just the username and isDefaultUsername fields
                await updateDoc(userRef, {
                    'userProfile.username': newUsername,
                    'userProfile.isDefaultUsername': false
                });
            }

            setUsername(newUsername);
            setIsEditing(false);
        } catch (err) {
            console.error('Detailed error:', err);
            setError(`An error occurred while updating username: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setError('');
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        
        if (newPassword !== confirmPassword) {
            return setPasswordError('New passwords do not match');
        }

        if (newPassword.length < 8) {
            return setPasswordError('Password must be at least 8 characters long');
        }

        try {
            setPasswordError('');
            setPasswordMessage('');
            setPasswordLoading(true);

            await updatePassword(auth.currentUser, newPassword);
            
            setPasswordMessage('Password updated successfully');
            setNewPassword('');
            setConfirmPassword('');
            
        } catch (error) {
            setPasswordError('Failed to update password. ' + error.message);
            if (error.code === 'auth/requires-recent-login') {
                setPasswordError('Please log out and log back in to change your password');
            }
        }
        
        setPasswordLoading(false);
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <h1>Account Settings</h1>
                    <div className="account-settings-content">
                        <h2>General Information</h2>
                        <div className="settings-grid">
                            <div className="field-row">
                                <span className="field-label">Email address:</span>
                                <span className="field-value">{user?.email}</span>
                                <span></span> {/* Empty span for grid alignment */}
                            </div>
                            <div className="field-row">
                                <span className="field-label">Username:</span>
                                <span className="field-value">{username}</span>
                                <button 
                                    onClick={handleEditClick}
                                    className="edit-button"
                                    aria-label="Edit username"
                                >
                                    <FaEdit />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="account-settings-change-password-content">
                        <h2>Change Password</h2>
                        {passwordError && <div className="error-message">{passwordError}</div>}
                        {passwordMessage && <div className="success-message">{passwordMessage}</div>}
                        
                        <form onSubmit={handlePasswordChange}>
                            <div className="password-field-row">
                                <label htmlFor="new-password">New Password:</label>
                                <input
                                    type="password"
                                    id="new-password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                    minLength="8"
                                />
                            </div>

                            <div className="password-field-row">
                                <label htmlFor="confirm-password">Confirm Password:</label>
                                <input
                                    type="password"
                                    id="confirm-password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    minLength="8"
                                />
                            </div>

                            <button 
                                type="submit" 
                                disabled={passwordLoading}
                                className="update-button"
                            >
                                {passwordLoading ? 'Updating...' : 'Update Password'}
                            </button>
                        </form>
                    </div>
                </div>

                {isEditing && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <h2>Change Username</h2>
                            <p>A username is what you'll go by here, so pick wisely.</p>
                            <input
                                type="text"
                                value={newUsername}
                                onChange={(e) => setNewUsername(e.target.value)}
                                disabled={loading}
                            />
                            {error && <p className="error-message">{error}</p>}
                            <div className="modal-buttons">
                                <button 
                                    onClick={handleSave}
                                    disabled={loading}
                                >
                                    {loading ? 'Saving...' : 'Save'}
                                </button>
                                <button 
                                    onClick={handleCancel}
                                    disabled={loading}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </main>
            <Footer />
        </>
    );
};

export default AccountSettings;