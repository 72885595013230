import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    collection, 
    query, 
    where, 
    getDocs, 
    orderBy, 
    limit, 
    collectionGroup, 
    getDoc 
} from 'firebase/firestore';
import { db } from '../firebase';
import './SearchBox.css';

const defaultProfileImage = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074";

const SearchBox = () => {
    const [searchType, setSearchType] = useState('communities');
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const searchRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setShowResults(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const searchContent = async (value) => {
        if (!value.trim()) {
            setResults([]);
            return;
        }

        const searchQuery = value.toLowerCase();
        let searchResults = [];

        try {
            if (searchType === 'users') {
                const usersQuery = query(
                    collection(db, 'users'),
                    where('userProfile.username', '>=', searchQuery),
                    where('userProfile.username', '<=', searchQuery + '\uf8ff'),
                    limit(3)
                );
                const userDocs = await getDocs(usersQuery);
                console.log('User search docs:', userDocs.docs.map(doc => doc.data()));
                const userData = userDocs.docs.map(doc => ({
                    type: 'user',
                    id: doc.id,
                    username: doc.data().userProfile.username,
                    displayName: doc.data().userProfile.displayName,
                    photoURL: doc.data().userProfile.profileImageUrl || defaultProfileImage
                }));
                searchResults = [...userData];
            }

            if (searchType === 'communities') {
                const communitiesQuery = query(
                    collection(db, 'communities'),
                    where('nameToLower', '>=', searchQuery),
                    where('nameToLower', '<=', searchQuery + '\uf8ff'),
                    limit(3)
                );
                const communityDocs = await getDocs(communitiesQuery);
                const communityData = communityDocs.docs.map(doc => ({
                    type: 'community',
                    ...doc.data()
                }));
                searchResults = [...searchResults, ...communityData];
            }

            if (searchType === 'posts') {
                // Only query for post titles
                const titleQuery = query(
                    collectionGroup(db, 'posts'),
                    where('title', '>=', searchQuery),
                    where('title', '<=', searchQuery + '\uf8ff'),
                    limit(3)
                );

                const titleDocs = await getDocs(titleQuery);
                
                // Process post results from title matches
                const titleResults = titleDocs.docs.map(doc => ({
                    type: 'post',
                    id: doc.id,
                    communityId: doc.ref.parent.parent.id,
                    matchType: 'title',
                    ...doc.data()
                }));

                searchResults = titleResults;
            }

            if (searchType === 'comments') {
                const searchTermWithTags = `<p>${searchQuery}`;
                
                const commentsQuery = query(
                    collectionGroup(db, 'comments'),
                    where('content', '>=', searchTermWithTags),
                    where('content', '<=', searchTermWithTags + '\uf8ff'),
                    limit(3)
                );

                const commentDocs = await getDocs(commentsQuery);
                
                const commentResults = await Promise.all(
                    commentDocs.docs.map(async (doc) => {
                        const postRef = doc.ref.parent.parent;
                        const postDoc = await getDoc(postRef);
                        
                        // Strip HTML tags for display
                        const cleanContent = doc.data().content.replace(/<[^>]*>/g, '');
                        
                        return {
                            type: 'comment',
                            id: doc.id,
                            postId: postDoc.id,
                            communityId: postRef.parent.parent.id,
                            commentContent: cleanContent,
                            createdAt: doc.data().createdAt,
                            authorId: doc.data().authorId,
                            ...postDoc.data()
                        };
                    })
                );

                searchResults = commentResults;
            }

            setResults(searchResults);
        } catch (error) {
            console.error('Search error:', error);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setShowResults(true);
        searchContent(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchTerm.trim()) {
            navigate(`/search?q=${encodeURIComponent(searchTerm)}&type=${searchType}`);
            setShowResults(false);
        }
    };

    return (
        <div className="search-box" ref={searchRef}>
            <form onSubmit={handleSubmit} className="search-form">
                <div className="search-input-container">
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleInputChange}
                        onFocus={() => setShowResults(true)}
                    />
                    <div className="search-selector">
                        <select 
                            value={searchType}
                            onChange={(e) => setSearchType(e.target.value)}
                            className="search-type-selector"
                        >
                            <option value="communities">Communities</option>
                            <option value="users">Users</option>
                            <option value="posts">Posts</option>
                            <option value="comments">Comments</option>
                        </select>
                        <i className="fa-solid fa-angle-down"></i>
                    </div>
                </div>
            </form>
            
            {showResults && results.length > 0 && (
                <div className="search-results">
                    {results.map((result, index) => (
                        <SearchResultItem 
                            key={index}
                            result={result}
                            setShowResults={setShowResults}
                            setSearchTerm={setSearchTerm}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const SearchResultItem = ({ result, setShowResults, setSearchTerm }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (result.type === 'user') {
            navigate(`/user/${result.username}`);
        } else if (result.type === 'community') {
            navigate(`/c/${result.name}`);
        } else if (result.type === 'post') {
            navigate(`/c/${result.communityId}/posts/${result.id}/${result.slug || ''}`);
        } else if (result.type === 'comment') {
            navigate(`/c/${result.communityId}/posts/${result.postId}/${result.slug || ''}#comment-${result.id}`);
        }
        setShowResults(false);
        setSearchTerm('');
    };

    return (
        <div className="search-result-item" onClick={handleClick}>
            {result.type === 'user' && (
                <div className="user-result">
                    <div className="user-header">
                        <img 
                            src={result.photoURL || '/default-avatar.png'}
                            alt="Profile" 
                            className="user-avatar"
                        />
                        <div className="user-info">
                            <span className="username">@{result.username}</span>
                            {result.displayName && (
                                <span className="display-name">{result.displayName}</span>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {result.type === 'community' && (
                <div className="community-result">
                    <div className="community-title">c/{result.name}</div>
                    {result.description && (
                        <div className="community-description">{result.description}</div>
                    )}
                    <div className="member-count">
                        {result.membersCount || 0} members
                    </div>
                </div>
            )}
            {result.type === 'post' && (
                <div className="post-result">
                    <div className="post-meta">
                        c/{result.communityId} • {result.createdAt ? new Date(result.createdAt.toDate()).toLocaleString('en-US', { 
                            hour: 'numeric',
                            minute: '2-digit'
                        }) : 'sometime ago'}
                    </div>
                    <div className="post-title">{result.title}</div>
                    <div className="post-stats">
                        <span className="votes">{result.voteCount || 0} votes</span>
                        <span className="dot">•</span>
                        <span className="comments">{result.commentCount || 0} comments</span>
                    </div>
                </div>
            )}
            {result.type === 'comment' && (
                <div className="comment-result">
                    <div className="post-meta">
                        c/{result.communityId} • {result.createdAt ? new Date(result.createdAt.toDate()).toLocaleString('en-US', { 
                            hour: 'numeric',
                            minute: '2-digit'
                        }) : 'sometime ago'}
                    </div>
                    <div className="comment-context">Re: {result.title}</div>
                    <div className="comment-content">{result.commentContent}</div>
                </div>
            )}
        </div>
    );
};

export default SearchBox;
