import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, limit } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaEdit } from 'react-icons/fa';
import './ClubEdit.css';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../firebase';

const defaultUserPhoto = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074";
const defaultClubPhoto = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultclub%2Fclub_default.png?alt=media&token=be631b3a-b250-45f0-a683-c1760dd9d96a"; 

const ClubEdit = () => {
    const { clubId } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [club, setClub] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState({});
    const [newValue, setNewValue] = useState('');
    const [clubData, setClubData] = useState({
        clubName: '',
        description: '',
        location: '',
        city: '',
        state: '',
        phone: '',
        email: '',
        website: '',
        clubOwner: '',
        socialMedia: {
            facebook: '',
            instagram: '',
            twitter: ''
        },
        weapons: {
            foil: false,
            epee: false,
            saber: false
        },
        membershipFees: '',
        classSchedule: '',
        coaches: '',
        facilities: '',
        achievements: '',
        clubAdmin: '',
        adminUsername: '',
        adminProfileImage: ''
    });
    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        const fetchClub = async () => {
            try {
                const q = query(
                    collection(db, 'clubs'),
                    where('accountIdLower', '==', clubId.toLowerCase())
                );
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const clubDoc = querySnapshot.docs[0];
                    const clubData = clubDoc.data();

                    // Fetch admin user data
                    if (clubData.clubAdmin) {
                        const adminDoc = await getDoc(doc(db, 'users', clubData.clubAdmin));
                        if (adminDoc.exists()) {
                            // Set both admin username and profile image
                            setClubData(prevData => ({
                                ...prevData,
                                ...clubData,
                                adminUsername: adminDoc.data().userProfile.username,
                                adminProfileImage: adminDoc.data().userProfile.profileImageUrl || defaultUserPhoto
                            }));
                        }
                    } else {
                        setClubData(prevData => ({
                            ...prevData,
                            ...clubData
                        }));
                    }

                    setClub(clubData);
                } else {
                    setError('Club not found');
                }
            } catch (err) {
                console.error('Error loading club:', err);
                setError('Error loading club: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchClub();
    }, [clubId]);

    // Show error message with a "Go Back" button if user is not authorized
    if (error) {
        return (
            <>
                <Header />
                <main>
                    <div className="page-container">
                        <div className="access-denied-container">
                            <div className="access-denied-section">
                                <h2>Access Denied</h2>
                                <p>{error}</p>
                                <button 
                                    className="button-primary"
                                    onClick={() => navigate(`/club/${clubId}`)}
                                >
                                    Back to Club Page
                                </button>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer />
            </>
        );
    }

    if (loading) return <div>Loading...</div>;

    const handleEditClick = (field) => {
        setIsEditing({ ...isEditing, [field]: true });
        setNewValue(club[field] || '');
        setError('');
    };

    const validateField = (field, value) => {
        if (field === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                return 'Please enter a valid email address';
            }
        }
        
        if (field === 'website') {
            const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
            if (!urlRegex.test(value)) {
                return 'Please enter a valid website URL';
            }
        }
        
        if (field === 'phone') {
            const phoneRegex = /^\+?[\d\s-()]{10,}$/;
            if (!phoneRegex.test(value)) {
                return 'Please enter a valid phone number';
            }
        }

        return null;
    };

    const handleSave = async (field) => {
        const validationError = validateField(field, newValue);
        if (validationError) {
            setError(validationError);
            return;
        }

        try {
            const q = query(
                collection(db, 'clubs'),
                where('accountIdLower', '==', clubId.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            const clubDoc = querySnapshot.docs[0];

            if (field.includes('socialMedia.')) {
                const platform = field.split('.')[1];
                await handleSocialMediaSave(platform);
                return;
            }

            await updateDoc(doc(db, 'clubs', clubDoc.id), {
                [field]: newValue
            });

            setClub(prev => ({
                ...prev,
                [field]: newValue
            }));
            setClubData(prev => ({
                ...prev,
                [field]: newValue
            }));
            setIsEditing({ ...isEditing, [field]: false });
        } catch (err) {
            console.error('Error updating club:', err);
            setError(`Error updating ${field}: ${err.message}`);
        }
    };

    const handleCancel = (field) => {
        setIsEditing({ ...isEditing, [field]: false });
        setError('');
    };

    const handleWeaponChange = (weapon) => {
        setClubData(prev => ({
            ...prev,
            weapons: {
                ...prev.weapons,
                [weapon]: !prev.weapons[weapon]
            }
        }));

        // Save weapons changes immediately
        const saveWeapons = async () => {
            try {
                const q = query(
                    collection(db, 'clubs'),
                    where('accountIdLower', '==', clubId.toLowerCase())
                );
                const querySnapshot = await getDocs(q);
                const clubDoc = querySnapshot.docs[0];

                await updateDoc(doc(db, 'clubs', clubDoc.id), {
                    [`weapons.${weapon}`]: !clubData.weapons[weapon]
                });
            } catch (err) {
                console.error('Error updating weapons:', err);
                setError(`Error updating weapons: ${err.message}`);
            }
        };

        saveWeapons();
    };

    const handleSocialMediaSave = async (platform) => {
        try {
            const q = query(
                collection(db, 'clubs'),
                where('accountIdLower', '==', clubId.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            const clubDoc = querySnapshot.docs[0];

            await updateDoc(doc(db, 'clubs', clubDoc.id), {
                [`socialMedia.${platform}`]: newValue
            });

            setClub(prev => ({
                ...prev,
                socialMedia: {
                    ...prev.socialMedia,
                    [platform]: newValue
                }
            }));
            setClubData(prev => ({
                ...prev,
                socialMedia: {
                    ...prev.socialMedia,
                    [platform]: newValue
                }
            }));
            setIsEditing({ ...isEditing, [`socialMedia.${platform}`]: false });
        } catch (err) {
            console.error('Error updating social media:', err);
            setError(`Error updating ${platform}: ${err.message}`);
        }
    };

    const handleModeratorSave = async (newModerator) => {
        if (!newModerator) return;

        try {
            const q = query(
                collection(db, 'clubs'),
                where('accountIdLower', '==', clubId.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            const clubDoc = querySnapshot.docs[0];

            await updateDoc(doc(db, 'clubs', clubDoc.id), {
                clubAdmin: newModerator.id,
                adminUsername: newModerator.username
            });

            setClubData(prev => ({
                ...prev,
                clubAdmin: newModerator.id,
                adminUsername: newModerator.username
            }));
            setIsEditing({ ...isEditing, moderator: false });
        } catch (err) {
            console.error('Error updating moderator:', err);
            setError('Error updating moderator: ' + err.message);
        }
    };

    const renderEditModal = (field, label, type = 'text') => {
        if (!isEditing[field]) return null;

        return (
            <div className="club-edit-modal-overlay">
                <div className="club-edit-modal-content">
                    <h2>{label}</h2>
                    <p>Enter {label.toLowerCase()}</p>
                    {type === 'textarea' ? (
                        <textarea
                            value={newValue}
                            onChange={(e) => setNewValue(e.target.value)}
                            className="textarea-input"
                            rows={6}
                        />
                    ) : type === 'weapons' ? (
                        <div className="weapons-checkboxes">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={clubData.weapons.foil}
                                    onChange={() => handleWeaponChange('foil')}
                                />
                                Foil
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={clubData.weapons.epee}
                                    onChange={() => handleWeaponChange('epee')}
                                />
                                Epee
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={clubData.weapons.saber}
                                    onChange={() => handleWeaponChange('saber')}
                                />
                                Saber
                            </label>
                        </div>
                    ) : (
                        <input
                            type={type}
                            value={newValue}
                            onChange={(e) => setNewValue(e.target.value)}
                            placeholder={`Enter ${label.toLowerCase()}`}
                        />
                    )}
                    {error && <p className="error-message">{error}</p>}
                    <div className="club-edit-modal-buttons">
                        <button 
                            onClick={() => handleSave(field)}
                            className="button-primary"
                        >
                            Save
                        </button>
                        <button 
                            onClick={() => handleCancel(field)}
                            className="button-secondary"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const ModeratorSearchModal = ({ isOpen, onClose, onSave }) => {
        const [searchQuery, setSearchQuery] = useState('');
        const [searchResults, setSearchResults] = useState([]);
        const [selectedUser, setSelectedUser] = useState(null);
        const [loading, setLoading] = useState(false);
        const [showDropdown, setShowDropdown] = useState(false);

        useEffect(() => {
            const timer = setTimeout(async () => {
                if (searchQuery.length >= 2) {
                    setLoading(true);
                    try {
                        const usersRef = collection(db, 'users');
                        const q = query(
                            usersRef,
                            where('userProfile.username', '>=', searchQuery),
                            where('userProfile.username', '<=', searchQuery + '\uf8ff'),
                            limit(5)
                        );
                        const snapshot = await getDocs(q);
                        const users = snapshot.docs.map(doc => ({
                            id: doc.id,
                            username: doc.data().userProfile.username,
                            profileImageUrl: doc.data().userProfile.profileImageUrl || defaultUserPhoto
                        }));
                        setSearchResults(users);
                    } catch (error) {
                        console.error('Error searching users:', error);
                    }
                    setLoading(false);
                } else {
                    setSearchResults([]);
                }
            }, 300);

            return () => clearTimeout(timer);
        }, [searchQuery]);

        const handleUserSelect = (selectedUser) => {
            console.log('Selecting user:', selectedUser); // Debug log
            setSelectedUser(selectedUser);
            setSearchQuery(selectedUser.username);
            setShowDropdown(false);
        };

        return (
            <div className="club-edit-modal-overlay">
                <div className="club-edit-modal-content" onClick={(e) => e.stopPropagation()}>
                    <h2>Reassign Admin Role</h2>
                    <p>Select the user who you want to reassign as the admin</p>
                    
                    <div className="club-edit-search-container">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                                setShowDropdown(true);
                            }}
                            onFocus={() => setShowDropdown(true)}
                            placeholder="Start typing username..."
                            className="club-edit-search-input"
                        />
                        
                        {showDropdown && (searchResults.length > 0 || loading) && (
                            <div className="club-edit-search-results">
                                {loading ? (
                                    <div className="club-edit-loading">Searching...</div>
                                ) : (
                                    searchResults.map((user, index) => (
                                        <div 
                                            key={user.id}
                                            className={`club-edit-user-result ${selectedUser?.id === user.id ? 'selected' : ''}`}
                                            onClick={() => handleUserSelect(user)}
                                        >
                                            <img 
                                                src={user.profileImageUrl} 
                                                alt={user.username}
                                                className="club-edit-user-avatar"
                                            />
                                            <span className="club-edit-username">{user.username}</span>
                                        </div>
                                    ))
                                )}
                            </div>
                        )}
                    </div>

                    <div className="club-edit-modal-buttons">
                        <button 
                            onClick={() => onSave(selectedUser)}
                            disabled={!selectedUser}
                            className="button-primary"
                        >
                            Save
                        </button>
                        <button 
                            onClick={onClose} 
                            className="button-secondary"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const handleImageUpload = async (event) => {
        try {
            if (!event.target.files || !event.target.files[0]) return;
            
            setIsUploading(true);
            const file = event.target.files[0];
            
            // Query for the club document first
            const q = query(
                collection(db, 'clubs'),
                where('accountIdLower', '==', clubId.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const clubDoc = querySnapshot.docs[0];
                const docId = clubDoc.id;
                
                // Add these debug logs
                console.log('Storage path:', `clubProfileImages/${docId}/profile.jpg`);
                console.log('User auth state:', !!user);
                console.log('User ID:', user?.uid);
                console.log('Club admin:', clubDoc.data().clubAdmin);
                console.log('Created by:', clubDoc.data().createdBy);
                
                const storageRef = ref(storage, `clubProfileImages/${docId}/profile.jpg`);
                
                // Upload the file
                const snapshot = await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(snapshot.ref);
                
                // Update Firestore document
                await updateDoc(clubDoc.ref, {
                    profileImageUrl: downloadURL
                });
                
                // Update local state
                setClubData(prev => ({
                    ...prev,
                    profileImageUrl: downloadURL
                }));
            }
        } catch (error) {
            console.error('Error in handleImageUpload:', error);
            setError('Failed to upload image: ' + error.message);
        } finally {
            setIsUploading(false);
        }
    };

    const handleDeleteClubImage = async () => {
        // First check if we're already using the default image
        if (clubData.profileImageUrl === defaultUserPhoto) {
            setError('Cannot remove default profile image');
            return;
        }

        if (!window.confirm('Are you sure you want to remove the club photo?')) return;

        try {
            setIsUploading(true);

            const q = query(
                collection(db, 'clubs'),
                where('accountIdLower', '==', clubId.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const clubDoc = querySnapshot.docs[0];

                // Delete the old image from storage
                if (clubData.profileImageUrl) {
                    try {
                        const oldImageRef = ref(storage, `clubProfileImages/${clubDoc.id}/profile.jpg`);
                        await deleteObject(oldImageRef);
                    } catch (error) {
                        console.error('Error deleting old image:', error);
                    }
                }

                // Update the document with default image
                await updateDoc(doc(db, 'clubs', clubDoc.id), {
                    profileImageUrl: defaultUserPhoto
                });

                setClubData(prev => ({
                    ...prev,
                    profileImageUrl: defaultUserPhoto
                }));
            }
        } catch (error) {
            console.error('Error deleting image:', error);
            setError('Failed to remove image: ' + error.message);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="club-edit-header">
                        <h1>Edit Club Profile</h1>
                        <button 
                            className="button-secondary"
                            onClick={() => navigate(`/club/${clubId}`)}
                        >
                            Back to Club Page
                        </button>
                    </div>
                    
                    <div className="club-edit-layout">
                        {/* Left Column - Club Image */}
                        <div className="club-edit-sidebar">
                            <div className="club-edit-image-container">
                                <img 
                                    src={clubData.profileImageUrl || defaultClubPhoto} 
                                    alt={clubData.clubName} 
                                    className="club-edit-image"
                                />
                            </div>
                            <div className="club-edit-image-actions">
                                <label className="button-secondary">
                                    Upload Image
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        style={{ display: 'none' }}
                                        disabled={isUploading}
                                    />
                                </label>
                                {clubData.profileImageUrl && clubData.profileImageUrl !== defaultUserPhoto && (
                                    <button
                                        className="button-tertiary"
                                        onClick={handleDeleteClubImage}
                                        disabled={isUploading}
                                    >
                                        Remove Photo
                                    </button>
                                )}
                            </div>
                        </div>

                        {/* Right Column - Club Information */}
                        <div className="club-edit-content">
                            <section className="edit-section">
                                <h2>Basic Information</h2>
                                <div className="field-row">
                                    <span className="field-label">Club Name:</span>
                                    <span className="field-value">{clubData.clubName}</span>
                                    <button 
                                        onClick={() => handleEditClick('clubName')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Description:</span>
                                    <span className="field-value">{clubData.description || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('description')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </section>

                            <section className="edit-section">
                                <h2>Location</h2>
                                <div className="field-row">
                                    <span className="field-label">Address:</span>
                                    <span className="field-value">{clubData.location || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('location')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">City:</span>
                                    <span className="field-value">{clubData.city || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('city')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">State:</span>
                                    <span className="field-value">{clubData.state || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('state')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </section>

                            <section className="edit-section">
                                <h2>Contact Information</h2>
                                <div className="field-row">
                                    <span className="field-label">Phone:</span>
                                    <span className="field-value">{clubData.phone || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('phone')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Email:</span>
                                    <span className="field-value">{clubData.email || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('email')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Website:</span>
                                    <span className="field-value">{clubData.website || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('website')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </section>

                            <section className="edit-section">
                                <h2>Social Media</h2>
                                <div className="field-row">
                                    <span className="field-label">Facebook:</span>
                                    <span className="field-value">{clubData.socialMedia?.facebook || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('socialMedia.facebook')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Instagram:</span>
                                    <span className="field-value">{clubData.socialMedia?.instagram || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('socialMedia.instagram')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Twitter:</span>
                                    <span className="field-value">{clubData.socialMedia?.twitter || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('socialMedia.twitter')}
                                        className="button-icon"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </section>

                            <section className="edit-section">
                                <h2>Club Details</h2>
                                <div className="field-row">
                                    <span className="field-label">Club Owner:</span>
                                    <span className="field-value">{clubData.clubOwner || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('clubOwner')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Membership Fees:</span>
                                    <span className="field-value">{clubData.membershipFees || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('membershipFees')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Class Schedule:</span>
                                    <span className="field-value">{clubData.classSchedule || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('classSchedule')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Coaches:</span>
                                    <span className="field-value">{clubData.coaches || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('coaches')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Facilities:</span>
                                    <span className="field-value">{clubData.facilities || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('facilities')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                                <div className="field-row">
                                    <span className="field-label">Achievements:</span>
                                    <span className="field-value">{clubData.achievements || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('achievements')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </section>

                            <section className="edit-section">
                                <h2>Administration</h2>
                                <div className="field-row">
                                    <span className="field-label">Admin:</span>
                                    <span className="field-value">
                                        {clubData.adminUsername ? (
                                            <div className="admin-info">
                                                <img 
                                                    src={clubData.adminProfileImage || defaultUserPhoto} 
                                                    alt={`${clubData.adminUsername}'s profile`} 
                                                    className="admin-image"
                                                />
                                                <span>{clubData.adminUsername}</span>
                                            </div>
                                        ) : 'Not set'}
                                    </span>
                                    <button 
                                        onClick={() => handleEditClick('moderator')}
                                        className="edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </section>
                        </div>
                    </div>
                    
                    {/* Render all edit modals */}
                    {renderEditModal('clubName', 'Club Name')}
                    {renderEditModal('description', 'Description', 'textarea')}
                    {renderEditModal('location', 'Address')}
                    {renderEditModal('city', 'City')}
                    {renderEditModal('state', 'State')}
                    {renderEditModal('phone', 'Phone')}
                    {renderEditModal('email', 'Email', 'email')}
                    {renderEditModal('website', 'Website', 'url')}
                    {renderEditModal('socialMedia.facebook', 'Facebook URL')}
                    {renderEditModal('socialMedia.instagram', 'Instagram Handle')}
                    {renderEditModal('socialMedia.twitter', 'Twitter Handle')}
                    {renderEditModal('membershipFees', 'Membership Fees', 'textarea')}
                    {renderEditModal('classSchedule', 'Class Schedule', 'textarea')}
                    {renderEditModal('coaches', 'Coaches', 'textarea')}
                    {renderEditModal('facilities', 'Facilities', 'textarea')}
                    {renderEditModal('achievements', 'Achievements', 'textarea')}
                    {isEditing.moderator && (
                        <ModeratorSearchModal
                            isOpen={true}
                            onClose={() => handleCancel('moderator')}
                            onSave={handleModeratorSave}
                        />
                    )}
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ClubEdit;
