import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

export const sendVerificationCode = async (email) => {
    try {
        console.log('Attempting to send verification code to:', email);
        const createVerificationCode = httpsCallable(functions, 'createVerificationCode');
        const result = await createVerificationCode({ email });
        console.log('Verification code result:', result);
        return result.data;
    } catch (error) {
        console.error('Error sending verification code:', error);
        throw error;
    }
};

export const verifyCode = async (email, code) => {
    try {
        console.log('Attempting to verify code:', { email, code });
        const verifyCodeFunction = httpsCallable(functions, 'verifyCode');
        const result = await verifyCodeFunction({ email, code });
        console.log('Verification result:', result);
        return result.data;
    } catch (error) {
        console.error('Error verifying code:', error);
        throw error;
    }
};
