import React from 'react';
import './AboutCommunity.css';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';

const defaultProfileImage = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074";

const AboutCommunity = ({ community }) => {
    return (
        <div className="about-community">
            <div className="about-community-image-container">
                <img 
                    src={community.communityImageUrl || defaultProfileImage}
                    alt={community.name}
                    className="about-community-image"
                />
            </div>
            <div className="about-community-content">
                <h2>About Community</h2>
                <div className="community-info">
                    <p>{community.description}</p>
                    <div className="members-count">
                        <span>{community.membersCount || 1}</span>
                        <span>Members</span>
                    </div>
                    <p className="created-date">
                        Created {formatDistanceToNow(community.createdAt?.toDate())} ago by{' '}
                        <Link to={`/user/${community.creatorUsername}`} className="username-link">
                            {community.creatorUsername}
                        </Link>
                    </p>
                </div>
                {community.moderatorUsername && (
                    <>
                        <div className="info-divider"></div>
                        <div className="info-section">
                            <div className="admin-label">Moderator</div>
                            <div className="admin-info">
                                <img 
                                    src={community.moderatorProfileImage || defaultProfileImage} 
                                    alt={`${community.moderatorUsername}'s profile`} 
                                    className="admin-image"
                                />
                                <Link to={`/user/${community.moderatorUsername}`} className="creator-link">
                                    {community.moderatorUsername}
                                </Link>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default AboutCommunity;
