import React from 'react';
import './SetupLayout.css';

const SetupLayout = ({ children }) => {
    return (
        <div className="setup-layout">
            <div className="setup-header">
                <div className="setup-logo">FencerStats</div>
            </div>
            <div className="setup-content">
                {children}
            </div>
        </div>
    );
};

export default SetupLayout;
