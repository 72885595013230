import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../firebase';
import './ResetPassword.css';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Get oobCode from URL parameters
    const oobCode = new URLSearchParams(location.search).get('oobCode');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        if (password.length < 6) {
            setError('Password should be at least 6 characters');
            return;
        }

        try {
            setError('');
            setLoading(true);
            
            await confirmPasswordReset(auth, oobCode, password);
            setSuccess(true);
            
            // Redirect to login after 3 seconds
            setTimeout(() => {
                navigate('/login');
            }, 3000);
            
        } catch (error) {
            console.error('Reset error:', error);
            setError('Failed to reset password. The link may have expired.');
        } finally {
            setLoading(false);
        }
    };

    if (!oobCode) {
        return (
            <div className="reset-password-container">
                <div className="reset-password-box">
                    <h2>Invalid Reset Link</h2>
                    <p>This password reset link is invalid. Please request a new one.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="reset-password-container">
            <div className="reset-password-box">
                <h2>Reset Password</h2>
                {error && <div className="error-message">{error}</div>}
                {success && (
                    <div className="success-message">
                        Password reset successful! Redirecting to login...
                    </div>
                )}
                
                {!success && (
                    <form onSubmit={handleSubmit}>
                        <div className="input-group">
                            <label htmlFor="password">New Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                minLength={6}
                            />
                        </div>
                        
                        <div className="input-group">
                            <label htmlFor="confirmPassword">Confirm Password</label>
                            <input
                                type="password"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                                minLength={6}
                            />
                        </div>
                        
                        <button 
                            type="submit" 
                            disabled={loading}
                            className="reset-button"
                        >
                            {loading ? 'Resetting...' : 'Reset Password'}
                        </button>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ResetPassword;
