import React, { useState, useEffect } from 'react';
import { doc, runTransaction, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

const CommentVote = ({ commentId, postId, communityName, initialVoteCount, onVoteChange }) => {
    const [voteCount, setVoteCount] = useState(initialVoteCount);
    const [userVote, setUserVote] = useState(0);
    const { user, isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const loadUserVote = async () => {
            if (!user) return;
            
            const votesQuery = query(
                collection(db, 'commentVotes'),
                where('commentId', '==', commentId),
                where('userId', '==', user.uid)
            );
            
            const snapshot = await getDocs(votesQuery);
            if (!snapshot.empty) {
                const voteDoc = snapshot.docs[0];
                setUserVote(voteDoc.data().voteValue);
            }
        };

        loadUserVote();
    }, [commentId, user]);

    const handleVote = async (voteValue) => {
        if (!isAuthenticated) {
            navigate('/login');
            return;
        }

        try {
            await runTransaction(db, async (transaction) => {
                const votesQuery = query(
                    collection(db, 'commentVotes'),
                    where('commentId', '==', commentId),
                    where('userId', '==', user.uid)
                );
                const voteSnapshot = await getDocs(votesQuery);
                
                const voteTotalRef = doc(db, 'commentVoteTotals', commentId);
                const voteTotalDoc = await transaction.get(voteTotalRef);
                
                let newTotal, newUpvotes, newDownvotes;
                
                if (!voteSnapshot.empty) {
                    const existingVote = voteSnapshot.docs[0];
                    const existingVoteValue = existingVote.data().voteValue;
                    
                    if (existingVoteValue === voteValue) {
                        transaction.delete(existingVote.ref);
                        newTotal = voteTotalDoc.data().voteTotal - voteValue;
                        newUpvotes = voteTotalDoc.data().upvotes - (voteValue === 1 ? 1 : 0);
                        newDownvotes = voteTotalDoc.data().downvotes - (voteValue === -1 ? 1 : 0);
                        setUserVote(0);
                    } else {
                        transaction.update(existingVote.ref, { voteValue });
                        newTotal = voteTotalDoc.data().voteTotal + (2 * voteValue);
                        newUpvotes = voteTotalDoc.data().upvotes + (voteValue === 1 ? 1 : -1);
                        newDownvotes = voteTotalDoc.data().downvotes + (voteValue === -1 ? 1 : -1);
                        setUserVote(voteValue);
                    }
                } else {
                    const newVoteRef = doc(collection(db, 'commentVotes'));
                    transaction.set(newVoteRef, {
                        commentId,
                        postId,
                        userId: user.uid,
                        communityId: communityName,
                        voteValue,
                        createdAt: new Date()
                    });
                    newTotal = voteTotalDoc.data().voteTotal + voteValue;
                    newUpvotes = voteTotalDoc.data().upvotes + (voteValue === 1 ? 1 : 0);
                    newDownvotes = voteTotalDoc.data().downvotes + (voteValue === -1 ? 1 : 0);
                    setUserVote(voteValue);
                }

                transaction.update(voteTotalRef, {
                    voteTotal: newTotal,
                    upvotes: newUpvotes,
                    downvotes: newDownvotes,
                    lastUpdated: new Date()
                });

                setVoteCount(newTotal);
                if (onVoteChange) {
                    onVoteChange(newTotal);
                }
            });
        } catch (error) {
            console.error('Error voting:', error);
        }
    };

    return (
        <div className="vote-container">
            <button 
                onClick={() => handleVote(1)}
                className={`vote-button upvote ${userVote === 1 ? 'active-upvote' : ''}`}
            >
                ▲
            </button>
            <span className="vote-count">{voteCount}</span>
            <button 
                onClick={() => handleVote(-1)}
                className={`vote-button downvote ${userVote === -1 ? 'active-downvote' : ''}`}
            >
                ▼
            </button>
        </div>
    );
};

export default CommentVote; 