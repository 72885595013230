import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="privacy-container">
                        <h1>Privacy Policy</h1>
                        
                        <p className="last-updated">Last Updated: January 13, 2025</p>

                        <section>
                            <h2>1. Introduction</h2>
                            <p>This Privacy Policy explains how Accelion Inc. ("we," "us," or "our") collects, uses, and protects your information when you use FencerStats ("Service"). For any privacy-related questions, please contact us at info@fencerstats.com.</p>
                        </section>

                        <section>
                            <h2>2. Children's Privacy</h2>
                            <p>Our Service requires users to be at least 13 years old. We do not knowingly collect information from children under 13. If you become aware that a child under 13 has provided us with personal information, please contact us. If we become aware that we have collected personal information from children under 13, we will take steps to remove that information.</p>
                        </section>

                        <section>
                            <h2>3. Information We Collect</h2>
                            <p>We collect:</p>
                            <ul>
                                <li>Account information (name, email, username)</li>
                                <li>Profile information (biography, location, photos)</li>
                                <li>Content you post (comments, posts, community contributions)</li>
                                <li>Usage data (interactions, preferences)</li>
                                <li>Device information (IP address, browser type)</li>
                                <li>Communications with other users</li>
                            </ul>
                        </section>

                        <section>
                            <h2>4. How We Use Your Information</h2>
                            <p>We use collected information to:
                                <ul>
                                    <li>Provide and improve our services</li>
                                    <li>Personalize your experience</li>
                                    <li>Process transactions</li>
                                    <li>Send notifications and updates</li>
                                    <li>Analyze usage patterns</li>
                                    <li>Prevent fraud and abuse</li>
                                </ul>
                            </p>
                        </section>

                        <section>
                            <h2>5. Information Sharing</h2>
                            <p>We may share information with:
                                <ul>
                                    <li>Other users (as per your privacy settings)</li>
                                    <li>Service providers, advertisers, and partners</li>
                                    <li>Legal authorities when required</li>
                                </ul>
                            </p>
                        </section>

                        <section>
                            <h2>6. Data Security</h2>
                            <p>We implement reasonable security measures to protect your information but cannot guarantee absolute security.</p>
                        </section>

                        <section>
                            <h2>7. Your Rights</h2>
                            <p>You have the right to:
                                <ul>
                                    <li>Access your personal data</li>
                                    <li>Correct inaccurate data</li>
                                    <li>Request deletion of your data</li>
                                    <li>Object to data processing</li>
                                    <li>Export your data</li>
                                </ul>
                            </p>
                        </section>

                        <section>
                            <h2>8. Cookies and Tracking</h2>
                            <p>We use cookies and similar technologies to improve user experience and analyze usage patterns.</p>
                        </section>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default PrivacyPolicy; 