import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import { formatDistanceToNow } from 'date-fns';
import CommentForm from './CommentForm';
import ReportModal from '../ReportModal';
import DeleteCommentModal from './DeleteCommentModal';
import { useAuth } from '../../AuthContext';
import RichTextEditor from '../RichTextEditor';
import CommentVote from '../CommentVote';

const DEFAULT_PROFILE_PHOTO = 'https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074';

const CommentItem = ({ comment, communityName, postId, replies, onVoteChange, onReplyAdded }) => {
    const [author, setAuthor] = useState(null);
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [showShareMenu, setShowShareMenu] = useState(false);
    const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
    const shareMenuRef = useRef(null);
    const [showDeleteCommentModal, setShowDeleteCommentModal] = useState(false);
    const { user, isAuthenticated } = useAuth();
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState(comment.content || '');

    useEffect(() => {
        const fetchAuthor = async () => {
            try {
                const userDoc = await getDoc(doc(db, 'users', comment.authorId));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setAuthor({
                        username: userData.userProfile?.username,
                        profilePhoto: userData.userProfile?.profileImageUrl || DEFAULT_PROFILE_PHOTO
                    });
                }
            } catch (error) {
                console.error('Error fetching author:', error);
            }
        };
        fetchAuthor();
    }, [comment.authorId]);

    useEffect(() => {
        setEditedContent(comment.content);
    }, [comment.content]);

    useEffect(() => {
        console.log('showReplyForm changed:', showReplyForm);
    }, [showReplyForm]);

    // Format the date safely
    const formatDate = (timestamp) => {
        if (!timestamp) return 'just now';
        
        // If it's already a Date object
        if (timestamp instanceof Date) {
            return formatDistanceToNow(timestamp) + ' ago';
        }
        
        // If it's a Firestore Timestamp
        if (timestamp.toDate) {
            return formatDistanceToNow(timestamp.toDate()) + ' ago';
        }
        
        // If it's a number (seconds or milliseconds)
        if (typeof timestamp === 'number') {
            const date = new Date(timestamp * 1000); // Convert seconds to milliseconds if needed
            return formatDistanceToNow(date) + ' ago';
        }
        
        return 'just now';
    };

    const nestedReplies = replies.filter(reply => reply.parentId === comment.id);

    const handleCopyLink = async () => {
        try {
            const commentUrl = `${window.location.origin}/c/${communityName}/posts/${postId}#comment-${comment.id}`;
            await navigator.clipboard.writeText(commentUrl);
            setShowCopiedTooltip(true);
            setTimeout(() => {
                setShowCopiedTooltip(false);
                setShowShareMenu(false);
            }, 2000);
        } catch (err) {
            console.error('Failed to copy:', err);
        }
    };

    const handleDeleteComment = async () => {
        try {
            const commentRef = doc(
                db, 
                'communities', 
                communityName.toLowerCase(), 
                'posts', 
                postId, 
                'comments', 
                comment.id
            );
            await updateDoc(commentRef, {
                isDeleted: true
            });
            setShowDeleteCommentModal(false);
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const handleSaveEdit = async () => {
        try {
            const commentRef = doc(
                db, 
                'communities', 
                communityName.toLowerCase(), 
                'posts', 
                postId, 
                'comments', 
                comment.id
            );

            await updateDoc(commentRef, {
                content: editedContent,
                lastEdited: new Date(),
                isHtml: true
            });

            setIsEditing(false);
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    };

    const handleReplyClick = (e) => {
        e.preventDefault(); // Prevent any default behavior
        e.stopPropagation(); // Prevent event bubbling
        setShowReplyForm(!showReplyForm);
    };

    const handleReplySubmit = (newReply) => {
        if (onReplyAdded) {
            onReplyAdded(newReply);
        }
        setShowReplyForm(false);
    };

    return (
        <div className="comment" id={`comment-${comment.id}`}>
            <div className="comment-content">
                <div className="comment-header">
                    <div className="comment-author-info">
                        <Link to={`/user/${author?.username}`}>
                            <img 
                                src={author?.profilePhoto || DEFAULT_PROFILE_PHOTO}
                                alt="User avatar" 
                                className="comment-avatar"
                            />
                        </Link>
                        <Link to={`/user/${author?.username}`} className="comment-author-link">
                            {author ? author.username : 'Loading...'}
                        </Link>
                        <span className="comment-time">
                            • {formatDate(comment.createdAt)}
                            {comment.lastEdited && (
                                <span className="edit-indicator">
                                    • Edited {formatDistanceToNow(
                                        comment.lastEdited instanceof Date 
                                            ? comment.lastEdited 
                                            : comment.lastEdited.toDate()
                                    )} ago
                                </span>
                            )}
                        </span>
                    </div>
                </div>
                {isEditing ? (
                    <div className="edit-comment-form">
                        <RichTextEditor
                            value={editedContent}
                            onChange={setEditedContent}
                            placeholder="Edit your comment..."
                        />
                        <div className="edit-actions">
                            <button 
                                className="cancel-button" 
                                onClick={() => {
                                    setIsEditing(false);
                                    setEditedContent(comment.content);
                                }}
                            >
                                Cancel
                            </button>
                            <button 
                                className="save-button" 
                                onClick={handleSaveEdit}
                                disabled={!editedContent.trim()}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="comment-text">
                        {comment.isDeleted ? (
                            <p className="deleted-message">Comment deleted by user</p>
                        ) : (
                            comment.isHtml ? (
                                <div dangerouslySetInnerHTML={{ __html: comment.content }} />
                            ) : (
                                comment.content
                            )
                        )}
                    </div>
                )}
                <div className="comment-actions">
                    <div className="vote-container">
                        <CommentVote 
                            commentId={comment.id}
                            postId={postId}
                            communityName={communityName}
                            initialVoteCount={comment.voteCount || 0}
                            onVoteChange={onVoteChange}
                        />
                    </div>
                    <button 
                        className="action-button"
                        onClick={handleReplyClick}
                    >
                        <i className="far fa-comment"></i>
                        Reply
                    </button>
                    {isAuthenticated && user?.uid === comment.authorId && !comment.isDeleted && (
                        <button 
                            className="action-button"
                            onClick={() => setIsEditing(true)}
                        >
                            <i className="far fa-edit"></i>
                            Edit
                        </button>
                    )}
                    <div className="share-button-container" ref={shareMenuRef}>
                        <button 
                            className="action-button"
                            onClick={() => setShowShareMenu(!showShareMenu)}
                        >
                            <i className="far fa-share-square"></i>
                            Share
                        </button>
                        {showShareMenu && (
                            <div className="share-menu">
                                <button 
                                    className="share-menu-item"
                                    onClick={handleCopyLink}
                                >
                                    {showCopiedTooltip ? (
                                        <>
                                            <i className="fas fa-check"></i>
                                            Copied!
                                        </>
                                    ) : (
                                        <>
                                            <i className="far fa-copy"></i>
                                            Copy link
                                        </>
                                    )}
                                </button>
                            </div>
                        )}
                    </div>
                    <button 
                        className="action-button"
                        onClick={() => setShowReportModal(true)}
                    >
                        <i className="fas fa-flag"></i>
                        Report
                    </button>
                    
                    <ReportModal
                        isOpen={showReportModal}
                        onClose={() => setShowReportModal(false)}
                        contentType="comment"
                        contentData={{
                            commentId: comment.id,
                            postId,
                            communityName,
                            authorId: comment.authorId,
                            commentContent: comment.content,
                            commentUrl: window.location.href
                        }}
                    />
                    {isAuthenticated && user?.uid === comment.authorId && !comment.isDeleted && (
                        <button 
                            className="action-button"
                            onClick={() => setShowDeleteCommentModal(true)}
                        >
                            <i className="far fa-trash-alt"></i>
                            Delete
                        </button>
                    )}
                </div>
            </div>
            {nestedReplies.length > 0 && (
                <div className="comment-replies">
                    {nestedReplies.map(reply => (
                        <CommentItem
                            key={reply.id}
                            comment={reply}
                            communityName={communityName}
                            postId={postId}
                            replies={replies}
                            onVoteChange={onVoteChange}
                        />
                    ))}
                </div>
            )}
            <DeleteCommentModal
                isOpen={showDeleteCommentModal}
                onClose={() => setShowDeleteCommentModal(false)}
                onConfirm={handleDeleteComment}
            />
            {showReplyForm && (
                <div className="reply-form-wrapper">
                    <CommentForm
                        postId={postId}
                        communityName={communityName}
                        parentId={comment.id}
                        onCommentAdded={handleReplySubmit}
                        onCancel={() => setShowReplyForm(false)}
                        isReply={true}
                    />
                </div>
            )}
        </div>
    );
};

export default CommentItem;
