import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle, signInWithFacebook } from '../googleAuth';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './SignUpPage.css';
import { sendVerificationCode, verifyCode } from '../services/verificationService';
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';

const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [error, setError] = useState('');
    const [step, setStep] = useState('EMAIL'); // EMAIL -> VERIFY -> CREDENTIALS
    const [resendDisabled, setResendDisabled] = useState(false);
    const [resendTimer, setResendTimer] = useState(0);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    const handleEmailSignUp = async (e) => {
        e.preventDefault();
        setError('');
        
        try {
            // Send verification code
            await sendVerificationCode(email);
            
            // Store email in localStorage for verification step
            localStorage.setItem('pendingSignUpEmail', email);
            
            setStep('VERIFY');
        } catch (error) {
            setError(error.message);
        }
    };

    const handleVerificationSubmit = async (e) => {
        e.preventDefault();
        setError('');
        
        try {
            const result = await verifyCode(email, verificationCode);
            
            // Create temporary password
            const tempPassword = Math.random().toString(36).slice(-8);
            
            // Create user account
            const userCredential = await createUserWithEmailAndPassword(auth, email, tempPassword);
            
            // Set temporary password flag in Firestore
            await setDoc(doc(db, 'users', userCredential.user.uid), {
                isTemporaryPassword: true,
                email: email,
                createdAt: new Date()
            });
            
            navigate('/setup', { 
                state: { email: email }
            });
        } catch (error) {
            console.error('Verification error:', error);
            setError(error.message || 'Error during verification');
        }
    };

    const handleResendCode = async () => {
        try {
            await sendVerificationCode(email);
            setResendDisabled(true);
            setResendTimer(30);
            
            const timer = setInterval(() => {
                setResendTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setResendDisabled(false);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        } catch (error) {
            setError('Error resending code');
        }
    };

    const renderVerificationStep = () => (
        <div className="confirmation-message">
            <h2>✉️ Check your email!</h2>
            <p>We've sent a verification code to:</p>
            <p className="email-address">{email}</p>
            <form onSubmit={handleVerificationSubmit}>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Enter verification code"
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        maxLength={6}
                        required
                    />
                </div>
                <button type="submit" className="email-button">
                    Verify Email
                </button>
            </form>
            <div className="instructions">
                <p>Don't see it? Check your spam folder or</p>
                <button className="resend-button" onClick={handleResendCode}>
                    Resend code
                </button>
            </div>
            <button 
                className="signup-back-button"
                onClick={() => {
                    setStep('EMAIL');
                    setEmail('');
                }}
            >
                Back to Sign Up
            </button>
        </div>
    );

    return (
        <>
            <Header hideAuthButton={true} />
            <div className="signup-page">
                <div className="signup-container">
                    {error && <p className="error">{error}</p>}
                    
                    {step === 'EMAIL' && (
                        <>
                            <h2>Create Account</h2>
                            <form onSubmit={handleEmailSignUp}>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                
                                <div className="terms-agreement">
                                    <p>
                                        By continuing, you agree to our{' '}
                                        <Link to="/terms">Terms of Service</Link> and{' '}
                                        <Link to="/privacy">Privacy Policy</Link>.
                                    </p>
                                </div>

                                <button type="submit" className="email-button">
                                    Continue
                                </button>
                            </form>

                            <div className="divider">
                                <span>OR</span>
                            </div>

                            <button 
                                className="google-button"
                                onClick={signInWithGoogle}
                            >
                                <img src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Google" />
                                Sign Up with Google
                            </button>

                            {/* Remove or comment out Facebook button
                            <button 
                                className="facebook-button"
                                onClick={signInWithFacebook}
                            >
                                <img src="/facebook-icon.svg" alt="Facebook" />
                                Sign Up with Facebook
                            </button>
                            */}
                        </>
                    )}
                    
                    {step === 'VERIFY' && renderVerificationStep()}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SignUpPage;
