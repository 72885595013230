import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../AuthContext';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaEdit } from 'react-icons/fa';
import './CommunityEdit.css';
import ModeratorSearchModal from '../components/ModeratorSearchModal';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../firebase';

const CommunityEdit = () => {
    const { communityName } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [community, setCommunity] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState({});
    const [newValue, setNewValue] = useState('');
    const [communityData, setCommunityData] = useState({
        name: '',
        description: '',
        moderators: [],
        moderatorUsername: '',
        moderatorProfileImage: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const defaultProfileImage = "https://firebasestorage.googleapis.com/v0/b/fencerstats.firebasestorage.app/o/defaultprofile%2Fdefault_profile.png?alt=media&token=708f4705-8d96-44a1-8e6c-95953ebbc074";

    // Fetch community data
    useEffect(() => {
        const fetchCommunity = async () => {
            if (!user) {
                navigate('/login');
                return;
            }

            try {
                // Use query instead of direct doc reference
                const q = query(
                    collection(db, 'communities'),
                    where('nameToLower', '==', communityName.toLowerCase())
                );
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const communityDoc = querySnapshot.docs[0];
                    const data = {
                        id: communityDoc.id,
                        ...communityDoc.data()
                    };

                    // Check moderator access
                    if (!data.moderators?.includes(user.uid)) {
                        navigate(`/c/${communityName}`);
                        return;
                    }

                    // Fetch moderator data
                    if (data.moderators && data.moderators.length > 0) {
                        const moderatorDoc = await getDoc(doc(db, 'users', data.moderators[0]));
                        if (moderatorDoc.exists()) {
                            const moderatorData = moderatorDoc.data();
                            data.moderatorUsername = moderatorData.userProfile.username;
                            data.moderatorProfileImage = moderatorData.userProfile.profileImageUrl;
                        }
                    }

                    console.log("Fetched community data:", data); // Debug log
                    setCommunityData(data);
                } else {
                    setError('Community not found');
                }
            } catch (err) {
                console.error('Error loading community:', err);
                setError('Error loading community: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCommunity();
    }, [communityName, user, navigate]);

    const handleEditClick = (field) => {
        setIsEditing({ ...isEditing, [field]: true });
        setNewValue(communityData[field] || '');
    };

    const handleSave = async (field) => {
        if (!newValue?.trim()) {
            showMessage('Field cannot be empty', true);
            return;
        }

        try {
            // Query for the community document first
            const q = query(
                collection(db, 'communities'),
                where('nameToLower', '==', communityName.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const communityDoc = querySnapshot.docs[0];
                await updateDoc(communityDoc.ref, {
                    [field]: newValue.trim()
                });
                
                setCommunityData(prev => ({
                    ...prev,
                    [field]: newValue.trim()
                }));
                
                setIsEditing({ ...isEditing, [field]: false });
                showMessage(`Successfully updated ${field}`);
            } else {
                throw new Error('Community not found');
            }
        } catch (error) {
            console.error('Error updating field:', error);
            showMessage(`Failed to update ${field}: ${error.message}`, true);
        }
    };

    const handleCancel = (field) => {
        setIsEditing({ ...isEditing, [field]: false });
        setNewValue('');
    };

    const handleModeratorSave = async (newModeratorId, newModeratorData) => {
        try {
            const q = query(
                collection(db, 'communities'),
                where('nameToLower', '==', communityName.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const communityDoc = querySnapshot.docs[0];
                await updateDoc(communityDoc.ref, {
                    moderators: [newModeratorId]
                });

                setCommunityData(prev => ({
                    ...prev,
                    moderators: [newModeratorId],
                    moderatorUsername: newModeratorData.username,
                    moderatorProfileImage: newModeratorData.profileImageUrl || defaultProfileImage
                }));

                setIsEditing({ ...isEditing, moderator: false });
                showMessage('Successfully updated moderator');
            }
        } catch (error) {
            console.error('Error updating moderator:', error);
            showMessage('Failed to update moderator: ' + error.message, true);
        }
    };

    const handleImageUpload = async (event) => {
        try {
            if (!event.target.files || !event.target.files[0]) return;
            
            setIsUploading(true);
            const file = event.target.files[0];
            
            // Query for the community document first
            const q = query(
                collection(db, 'communities'),
                where('nameToLower', '==', communityName.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const communityDoc = querySnapshot.docs[0];
                const communityId = communityDoc.id; // Make sure we're using the document ID
                
                // Create storage reference using the document ID
                const storageRef = ref(storage, `communityProfileImages/${communityId}/profile.jpg`);
                
                // Upload the file
                const snapshot = await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(snapshot.ref);
                
                // Update Firestore document
                await updateDoc(communityDoc.ref, {
                    communityImageUrl: downloadURL
                });
                
                // Update local state
                setCommunityData(prev => ({
                    ...prev,
                    communityImageUrl: downloadURL
                }));
                
                showMessage('Successfully updated community image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            showMessage('Failed to upload image: ' + error.message, true);
        } finally {
            setIsUploading(false);
        }
    };

    const handleDeleteCommunityImage = async () => {
        if (communityData.communityImageUrl === defaultProfileImage) {
            showMessage('Cannot remove default profile image', true);
            return;
        }

        if (!window.confirm('Are you sure you want to remove the community photo?')) return;

        try {
            setIsUploading(true);

            const q = query(
                collection(db, 'communities'),
                where('nameToLower', '==', communityName.toLowerCase())
            );
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                const communityDoc = querySnapshot.docs[0];

                // Delete the old image from storage
                if (communityData.communityImageUrl) {
                    try {
                        const oldImageRef = ref(storage, `communityProfileImages/${communityDoc.id}/profile.jpg`);
                        await deleteObject(oldImageRef);
                    } catch (error) {
                        console.error('Error deleting old image:', error);
                    }
                }

                // Update the document with default image
                await updateDoc(communityDoc.ref, {
                    communityImageUrl: defaultProfileImage
                });

                setCommunityData(prev => ({
                    ...prev,
                    communityImageUrl: defaultProfileImage
                }));
                
                showMessage('Successfully removed community image');
            }
        } catch (error) {
            console.error('Error deleting image:', error);
            showMessage('Failed to remove image: ' + error.message, true);
        } finally {
            setIsUploading(false);
        }
    };

    const renderEditModal = (field, label, type = 'text') => {
        if (!isEditing[field]) return null;

        return (
            <div className="modal-overlay">
                <div className="community-edit-modal-content">
                    <h2>Edit {label}</h2>
                    <p>Enter a {label.toLowerCase()}</p>
                    {type === 'textarea' ? (
                        <textarea
                            value={newValue}
                            onChange={(e) => setNewValue(e.target.value)}
                            className="textarea-input"
                            rows={6}
                            placeholder={`Enter ${label.toLowerCase()}`}
                        />
                    ) : (
                        <input
                            type={type}
                            value={newValue}
                            onChange={(e) => setNewValue(e.target.value)}
                            className="edit-input"
                        />
                    )}
                    <div className="community-edit-modal-buttons">
                        <button onClick={() => handleCancel(field)} className="button-secondary">
                            Cancel
                        </button>
                        <button onClick={() => handleSave(field)} className="button-primary">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const showMessage = (message, isError = false) => {
        if (isError) {
            setErrorMessage(message);
            setTimeout(() => setErrorMessage(''), 5000);
        } else {
            setSuccessMessage(message);
            setTimeout(() => setSuccessMessage(''), 5000);
        }
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="community-edit-header">
                        <h1>Edit Community Profile</h1>
                        <button 
                            className="button-secondary"
                            onClick={() => navigate(`/c/${communityName}`)}
                        >
                            Back to Community Page
                        </button>
                    </div>

                    <div className="community-edit-layout">
                        {/* Left Column - Community Image */}
                        <div className="community-edit-sidebar">
                            <div className="community-edit-image-container">
                                <img 
                                    src={communityData.communityImageUrl || defaultProfileImage} 
                                    alt={communityData.name} 
                                    className="community-edit-image"
                                />
                            </div>
                            <div className="community-edit-image-actions">
                                <label className="button-secondary">
                                    {isUploading ? 'Uploading...' : 'Upload Image'}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        style={{ display: 'none' }}
                                        disabled={isUploading}
                                    />
                                </label>
                                {communityData.communityImageUrl && communityData.communityImageUrl !== defaultProfileImage && (
                                    <button
                                        className="button-tertiary"
                                        onClick={handleDeleteCommunityImage}
                                        disabled={isUploading}
                                    >
                                        Remove Photo
                                    </button>
                                )}
                            </div>
                        </div>

                        {/* Right Column - Community Information */}
                        <div className="community-edit-content">
                            <section className="community-edit-section">
                                <h2>Basic Information</h2>
                                <div className="community-field-row">
                                    <span className="community-field-label">Description:</span>
                                    <span className="community-field-value">{communityData.description || 'Not set'}</span>
                                    <button 
                                        onClick={() => handleEditClick('description')}
                                        className="community-edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </section>

                            <section className="community-edit-section">
                                <h2>Administration</h2>
                                <div className="community-field-row">
                                    <span className="community-field-label">Moderator:</span>
                                    <span className="community-field-value">
                                        {communityData.moderatorUsername ? (
                                            <div className="community-moderator-info">
                                                <img 
                                                    src={communityData.moderatorProfileImage || defaultProfileImage} 
                                                    alt={`${communityData.moderatorUsername}'s profile`} 
                                                    className="community-moderator-image"
                                                />
                                                <span>{communityData.moderatorUsername}</span>
                                            </div>
                                        ) : 'Not set'}
                                    </span>
                                    <button 
                                        onClick={() => handleEditClick('moderator')}
                                        className="community-edit-button"
                                    >
                                        <FaEdit />
                                    </button>
                                </div>
                            </section>
                        </div>
                    </div>

                    {renderEditModal('description', 'Description', 'textarea')}
                    {isEditing.moderator && (
                        <ModeratorSearchModal
                            isOpen={true}
                            onClose={() => handleCancel('moderator')}
                            onSave={handleModeratorSave}
                            currentModeratorId={communityData.moderators?.[0]}
                            title="Reassign Moderator Role"
                            description="Select the user who you want to reassign as the moderator"
                        />
                    )}
                </div>
            </main>
            <Footer />
        </>
    );
}

export default CommunityEdit;
