import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../AuthContext';
import CommentForm from './CommentForm';
import CommentItem from './CommentItem';
import './Comments.css';
import { DEFAULT_PROFILE_PHOTO } from '../../utils/constants';
import CommentVote from '../CommentVote';

const Comments = ({ postId, communityName }) => {
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user } = useAuth();

    useEffect(() => {
        if (!postId || !communityName) return;

        let unsubscribe;
        const loadComments = async () => {
            try {
                const commentsRef = collection(db, 'communities', communityName, 'posts', postId, 'comments');
                const q = query(commentsRef, orderBy('createdAt', 'desc'));
                
                unsubscribe = onSnapshot(q, async (snapshot) => {
                    const commentData = [];
                    
                    for (const docSnapshot of snapshot.docs) {
                        const data = docSnapshot.data();
                        const [userDoc, voteTotalDoc] = await Promise.all([
                            getDoc(doc(db, 'users', data.authorId)),
                            getDoc(doc(db, 'commentVoteTotals', docSnapshot.id))
                        ]);

                        const userData = userDoc.exists() ? userDoc.data().userProfile : null;
                        const voteData = voteTotalDoc.exists() ? voteTotalDoc.data() : { voteTotal: 0 };

                        commentData.push({
                            id: docSnapshot.id,
                            ...data,
                            createdAt: data.createdAt?.toDate(),
                            authorProfilePhoto: userData?.profileImageUrl || DEFAULT_PROFILE_PHOTO,
                            authorUsername: userData?.username,
                            voteCount: voteData.voteTotal
                        });
                    }
                    
                    setComments(commentData);
                    setLoading(false);
                });
            } catch (error) {
                console.error("Error setting up comments listener:", error);
                setLoading(false);
            }
        };

        loadComments();
        return () => unsubscribe && unsubscribe();
    }, [postId, communityName]);

    const handleCommentEdit = async (commentId, newContent) => {
        try {
            const commentRef = doc(
                db,
                'communities',
                communityName,
                'posts',
                postId,
                'comments',
                commentId
            );
            await updateDoc(commentRef, {
                content: newContent,
                lastEdited: new Date()
            });
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    };

    // Organize comments into a tree structure
    const topLevelComments = comments.filter(comment => !comment.parentId);
    const replies = comments.filter(comment => comment.parentId);

    const getCommentsLabel = (count) => {
        if (count === 0) return 'Comments';
        if (count === 1) return '1 Comment';
        return `${count} Comments`;
    };

    if (loading) return <div className="comments-section">Loading comments...</div>;

    return (
        <div className="comments-section">
            <h3>{getCommentsLabel(comments.length)}</h3>
            <CommentForm 
                postId={postId} 
                communityName={communityName}
                onCommentAdded={(newComment) => {
                    setComments(prev => [newComment, ...prev]);
                }}
            />
            {topLevelComments.length > 0 ? (
                topLevelComments.map(comment => (
                    <CommentItem
                        key={comment.id}
                        comment={comment}
                        communityName={communityName}
                        postId={postId}
                        replies={replies}
                        onVoteChange={(newCount) => {
                            setComments(prevComments => 
                                prevComments.map(c => 
                                    c.id === comment.id 
                                        ? { ...c, voteCount: newCount }
                                        : c
                                )
                            );
                        }}
                        onReplyAdded={(newReply) => {
                            setComments(prev => [...prev, newReply]);
                        }}
                    />
                ))
            ) : (
                <div className="no-comments">No comments yet. Be the first to comment!</div>
            )}
        </div>
    );
};

export default Comments;
