import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './TermsOfService.css';

const TermsOfService = () => {
    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="terms-container">
                        <h1>Terms of Service</h1>
                        
                        <p className="last-updated">Last Updated: January 13, 2025</p>
                        
                        <section>
                            <h2>1. Introduction</h2>
                            <p>FencerStats ("Service") is owned and operated by Accelion Inc. ("we," "us," or "our"). By accessing or using FencerStats, you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, do not use the Service.</p>
                            <p>For any questions regarding these Terms, please contact us at info@fencerstats.com.</p>
                        </section>

                        <section>
                            <h2>2. Age Restrictions and Verification</h2>
                            <p>By using the Service, you represent and warrant that you are at least 13 years of age. If you are under 18 years old, you represent that you have obtained your parent/guardian's consent to use the Service. We reserve the right to terminate accounts of users found to be under 13 years of age.</p>
                            <p>Parents or legal guardians who allow their child to use the Service acknowledge and agree to these Terms on behalf of their child and take full responsibility for their child's use of the Service.</p>
                        </section>

                        <section>
                            <h2>3. Account Registration</h2>
                            <p>Users must:</p>
                            <ul>
                                <li>Provide accurate and complete information</li>
                                <li>Maintain the security of their account credentials</li>
                                <li>Promptly update any changes to their information</li>
                                <li>Accept responsibility for all activities under their account</li>
                            </ul>
                            <p>We reserve the right to suspend or terminate accounts that violate these Terms.</p>
                        </section>

                        <section>
                            <h2>4. User Content</h2>
                            <p>By posting content on FencerStats, you:</p>
                            <ul>
                                <li>Retain ownership of your content</li>
                                <li>Grant us a worldwide, non-exclusive, royalty-free license to use, modify, reproduce, and distribute your content</li>
                                <li>Represent that you have all necessary rights to post the content</li>
                                <li>Accept responsibility for ensuring content complies with all applicable laws</li>
                            </ul>
                            <p>Prohibited content includes:</p>
                            <ul>
                                <li>Illegal, harmful, or offensive material</li>
                                <li>Content that infringes on others' rights</li>
                                <li>Spam or unauthorized commercial content</li>
                                <li>Malware or malicious code</li>
                            </ul>
                        </section>

                        <section>
                            <h2>5. Community Guidelines</h2>
                            <p>Users must:</p>
                            <ul>
                                <li>Treat others with respect</li>
                                <li>Not engage in harassment or bullying</li>
                                <li>Not impersonate others</li>
                                <li>Not manipulate votes or engagement</li>
                                <li>Not post false or misleading information</li>
                            </ul>
                        </section>

                        <section>
                            <h2>6. Data and Statistics</h2>
                            <p>While we strive for accuracy in fencing statistics and results:</p>
                            <ul>
                                <li>We do not guarantee complete accuracy</li>
                                <li>Users should verify critical information independently</li>
                                <li>We are not responsible for decisions made based on this data</li>
                            </ul>
                        </section>

                        <section>
                            <h2>7. Third-Party Links and Content</h2>
                            <p>The Service may contain links to third-party websites and content (including social media). We are not responsible for third-party content or practices.</p>
                        </section>

                        <section>
                            <h2>8. Intellectual Property</h2>
                            <p>The Service's trademarks, logos, software, and other materials are protected by intellectual property laws. Users may not copy, modify, or distribute these materials without permission.</p>
                        </section>

                        <section>
                            <h2>9. Disclaimer of Warranties</h2>
                            <p>THE SERVICE IS PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND. WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED.</p>
                        </section>

                        <section>
                            <h2>10. Limitation of Liability</h2>
                            <p>ACCELION INC. SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING FROM USE OF THE SERVICE.</p>
                        </section>

                        <section>
                            <h2>11. Indemnification</h2>
                            <p>You agree to indemnify and hold harmless Accelion Inc. and its officers, directors, employees, and agents from any claims arising from your use of the Service or violation of these Terms.</p>
                        </section>

                        <section>
                            <h2>12. Changes to Terms</h2>
                            <p>We may modify these Terms at any time. Continued use of the Service after changes constitutes acceptance of the modified Terms.</p>
                        </section>

                        <section>
                            <h2>13. Governing Law</h2>
                            <p>These Terms are governed by the laws of Delaware, United States, without regard to conflicts of law principles.</p>
                        </section>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default TermsOfService; 