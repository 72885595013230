import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { collection, query, where, getDocs, getDoc, collectionGroup, limit } from 'firebase/firestore';
import { db } from '../firebase';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './SearchResults.css';

const SearchResults = () => {
    const [searchParams] = useSearchParams();
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const searchQuery = searchParams.get('q') || '';
    const searchType = searchParams.get('type') || 'communities';
    const navigate = useNavigate();

    useEffect(() => {
        const performSearch = async () => {
            if (!searchQuery) {
                setLoading(false);
                return;
            }

            console.log('Current search type:', searchType);
            console.log('Current search query:', searchQuery);

            setLoading(true);
            const searchTerm = searchQuery.toLowerCase();
            let searchResults = [];

            try {
                if (searchType === 'users') {
                    console.log('Performing user search...');
                    const usersQuery = query(
                        collection(db, 'users'),
                        where('userProfile.username', '>=', searchTerm),
                        where('userProfile.username', '<=', searchTerm + '\uf8ff'),
                        limit(3)
                    );
                    const userDocs = await getDocs(usersQuery);
                    console.log('User search docs:', userDocs.docs.map(doc => doc.data()));
                    const userData = userDocs.docs.map(doc => ({
                        type: 'user',
                        id: doc.id,
                        username: doc.data().userProfile.username,
                        profileImageUrl: doc.data().userProfile.profileImageUrl,
                        displayName: doc.data().userProfile.displayName,
                        ...doc.data().userProfile
                    }));
                    searchResults = [...userData];
                }

                else if (searchType === 'communities') {
                    console.log('Performing community search...');
                    const communitiesQuery = query(
                        collection(db, 'communities'),
                        where('nameToLower', '>=', searchTerm),
                        where('nameToLower', '<=', searchTerm + '\uf8ff'),
                        limit(3)
                    );
                    const communityDocs = await getDocs(communitiesQuery);
                    const communityData = communityDocs.docs.map(doc => ({
                        type: 'community',
                        ...doc.data()
                    }));
                    searchResults = [...communityData];
                }

                else if (searchType === 'posts') {
                    console.log('Performing post search...');
                    // Use collectionGroup to search across all posts in all communities
                    const titleQuery = query(
                        collectionGroup(db, 'posts'),
                        where('title', '>=', searchTerm),
                        where('title', '<=', searchTerm + '\uf8ff'),
                        limit(2)
                    );

                    const contentQuery = query(
                        collectionGroup(db, 'posts'),
                        where('content', '>=', searchTerm),
                        where('content', '<=', searchTerm + '\uf8ff'),
                        limit(2)
                    );

                    const commentsQuery = query(
                        collectionGroup(db, 'comments'),
                        where('content', '>=', searchTerm),
                        where('content', '<=', searchTerm + '\uf8ff'),
                        limit(2)
                    );

                    const [titleDocs, contentDocs, commentDocs] = await Promise.all([
                        getDocs(titleQuery),
                        getDocs(contentQuery),
                        getDocs(commentsQuery)
                    ]);

                    // Process title matches
                    const titleResults = await Promise.all(titleDocs.docs.map(async doc => {
                        // Get comment count from comments collection
                        const commentsRef = collection(db, 'communities', doc.ref.parent.parent.id, 'posts', doc.id, 'comments');
                        const commentsSnapshot = await getDocs(commentsRef);
                        
                        return {
                            type: 'post',
                            id: doc.id,
                            communityId: doc.ref.parent.parent.id,
                            matchType: 'title',
                            voteCount: doc.data().voteCount || 0,
                            commentCount: commentsSnapshot.size,
                            ...doc.data()
                        };
                    }));

                    // Process content matches
                    const contentResults = await Promise.all(contentDocs.docs.map(async doc => {
                        // Get comment count from comments collection
                        const commentsRef = collection(db, 'communities', doc.ref.parent.parent.id, 'posts', doc.id, 'comments');
                        const commentsSnapshot = await getDocs(commentsRef);
                        
                        return {
                            type: 'post',
                            id: doc.id,
                            communityId: doc.ref.parent.parent.id,
                            matchType: 'content',
                            voteCount: doc.data().voteCount || 0,
                            commentCount: commentsSnapshot.size,
                            ...doc.data()
                        };
                    }));

                    // Process comment matches
                    const commentResults = await Promise.all(
                        commentDocs.docs.map(async (doc) => {
                            const postRef = doc.ref.parent.parent;
                            const postDoc = await getDoc(postRef);
                            const postData = postDoc.data();
                            const commentData = doc.data();
                            
                            // Get comment count from comments collection
                            const commentsRef = collection(db, 'communities', postRef.parent.parent.id, 'posts', postDoc.id, 'comments');
                            const commentsSnapshot = await getDocs(commentsRef);
                            const commentCount = commentsSnapshot.size;
                            
                            return {
                                type: 'comment',
                                id: doc.id,
                                postId: postDoc.id,
                                communityId: postRef.parent.parent.id,
                                commentContent: commentData.content.replace(/<[^>]*>/g, ''),
                                createdAt: commentData.createdAt,
                                authorId: commentData.authorId,
                                title: postData.title,
                                voteCount: commentData.voteCount || 0,
                                postVoteCount: postData.voteCount || 0,
                                commentCount: commentCount,
                                slug: postData.slug || postData.title.toLowerCase()
                                    .replace(/[^a-z0-9]+/g, '-')
                                    .replace(/(^-|-$)/g, ''),
                                ...postData
                            };
                        })
                    );

                    // Combine all results and remove duplicates based on post ID
                    const allResults = [...titleResults, ...contentResults, ...commentResults];
                    const uniqueResults = Array.from(
                        new Map(allResults.map(item => [item.id, item])).values()
                    );

                    console.log('Title Results:', titleResults);
                    console.log('Content Results:', contentResults);
                    console.log('Comment Results:', commentResults);

                    searchResults = uniqueResults.slice(0, 3);
                }

                else if (searchType === 'comments') {
                    console.log('Performing comment search...');
                    const searchTermWithTags = `<p>${searchTerm}`;
                    
                    const commentsQuery = query(
                        collectionGroup(db, 'comments'),
                        where('content', '>=', searchTermWithTags),
                        where('content', '<=', searchTermWithTags + '\uf8ff'),
                        limit(10)
                    );

                    const commentDocs = await getDocs(commentsQuery);
                    
                    const commentResults = await Promise.all(
                        commentDocs.docs.map(async (doc) => {
                            const postRef = doc.ref.parent.parent;
                            const postDoc = await getDoc(postRef);
                            const postData = postDoc.data();
                            const commentData = doc.data();
                            
                            // Get comment count from comments collection
                            const commentsRef = collection(db, 'communities', postRef.parent.parent.id, 'posts', postDoc.id, 'comments');
                            const commentsSnapshot = await getDocs(commentsRef);
                            const commentCount = commentsSnapshot.size;
                            
                            return {
                                type: 'comment',
                                id: doc.id,
                                postId: postDoc.id,
                                communityId: postRef.parent.parent.id,
                                commentContent: commentData.content.replace(/<[^>]*>/g, ''),
                                createdAt: commentData.createdAt,
                                authorId: commentData.authorId,
                                title: postData.title,
                                voteCount: commentData.voteCount || 0,
                                postVoteCount: postData.voteCount || 0,
                                commentCount: commentCount,
                                slug: postData.slug || postData.title.toLowerCase()
                                    .replace(/[^a-z0-9]+/g, '-')
                                    .replace(/(^-|-$)/g, ''),
                                ...postData
                            };
                        })
                    );

                    searchResults = commentResults;
                }

                console.log('Final search results:', searchResults);
                setResults(searchResults);
            } catch (error) {
                console.error('Search error:', error);
            } finally {
                setLoading(false);
            }
        };

        performSearch();
    }, [searchQuery, searchType]);

    const renderResult = (result) => {
        switch (result.type) {
            case 'user':
                return (
                    <Link to={`/user/${result.username}`} className="search-result-link">
                        <div className="user-result">
                            <div className="user-header">
                                <img 
                                    src={result.profileImageUrl || '/default-avatar.png'} 
                                    alt="Profile" 
                                    className="user-avatar"
                                />
                                <div className="user-title">@{result.username}</div>
                            </div>
                            {result.fullName && (
                                <div className="user-description">{result.fullName}</div>
                            )}
                            {result.bio && (
                                <div className="user-bio">{result.bio}</div>
                            )}
                        </div>
                    </Link>
                );
            case 'community':
                return (
                    <Link to={`/c/${result.name}`} className="community-link">
                        <div className="community-title">c/{result.name}</div>
                        <div className="community-description">{result.description}</div>
                        <div className="member-count">
                            {result.membersCount || 0} members
                        </div>
                    </Link>
                );
            case 'post':
                const voteCount = (result.upvotes || 0) - (result.downvotes || 0);
                return (
                    <Link 
                        to={`/c/${result.communityId}/posts/${result.id}/${result.slug}`} 
                        className="search-result-link"
                    >
                        <div className="post-result">
                            <div className="post-meta">
                                c/{result.communityId} • {result.createdAt ? new Date(result.createdAt.toDate()).toLocaleString('en-US', { 
                                    hour: 'numeric',
                                    minute: '2-digit',
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric'
                                }) : 'sometime ago'}
                            </div>
                            <h3 className="post-title">{result.title}</h3>
                            <div className="post-stats">
                                <span className="votes">{result.voteCount || 0} votes</span>
                                <span className="dot">•</span>
                                <span className="comments">{result.commentCount || 0} comments</span>
                                {result.matchType === 'comment' && (
                                    <>
                                        <span className="dot">•</span>
                                        <span className="match-type">Matched in comment</span>
                                    </>
                                )}
                            </div>
                        </div>
                    </Link>
                );
            case 'comment':
                return (
                    <div className="comment-search-result">
                        <div className="comment-header">
                            <Link to={`/c/${result.communityId}`} className="community-link">
                                c/{result.communityId}
                            </Link>
                            <span className="dot">•</span>
                            <span className="post-time">
                                Posted {result.createdAt ? new Date(result.createdAt.toDate()).toLocaleString('en-US', { 
                                    month: 'short',
                                    day: 'numeric',
                                    year: 'numeric'
                                }) : 'sometime ago'}
                            </span>
                        </div>

                        <div className="post-context">
                            <Link 
                                to={`/c/${result.communityId}/posts/${result.postId}/${result.slug}`}
                                className="post-title-link"
                            >
                                {result.title}
                            </Link>
                        </div>

                        <div className="comment-body">
                            <div className="comment-metadata">
                                <Link to={`/user/${result.authorUsername}`} className="comment-author">
                                    {result.authorUsername}
                                </Link>
                                <span className="dot">•</span>
                                <span className="comment-time">
                                    {result.createdAt ? new Date(result.createdAt.toDate()).toLocaleString('en-US', { 
                                        month: 'short',
                                        day: 'numeric',
                                        year: 'numeric',
                                        hour: 'numeric',
                                        minute: '2-digit'
                                    }) : 'sometime ago'}
                                </span>
                            </div>
                            <div className="comment-content">
                                {result.commentContent}
                            </div>
                            <div className="comment-stats">
                                <span className="comment-votes">
                                    {result.voteCount || 0} votes
                                </span>
                            </div>
                        </div>

                        <div className="comment-thread-section">
                            <div className="comment-thread-container">
                                <Link 
                                    to={`/c/${result.communityId}/posts/${result.postId}/${result.slug}#comment-${result.id}`}
                                    className="go-to-thread"
                                >
                                    Go to Thread
                                </Link>
                                <div className="comment-post-stats">
                                    <span className="total-votes">{result.postVoteCount || 0} votes</span>
                                    <span className="dot">•</span>
                                    <span className="total-comments">{result.commentCount || 0} comments</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Header />
            <main>
                <div className="page-container">
                    <div className="search-container">
                        <div className="search-header">
                            <h1>Search Results for "{searchQuery}"</h1>
                        </div>
                        
                        <div className="search-type-tabs">
                            <button 
                                className={searchType === 'communities' ? 'active' : ''} 
                                onClick={() => navigate(`/search?q=${searchQuery}&type=communities`)}
                            >
                                Communities
                            </button>
                            <button 
                                className={searchType === 'users' ? 'active' : ''} 
                                onClick={() => navigate(`/search?q=${searchQuery}&type=users`)}
                            >
                                Users
                            </button>
                            <button 
                                className={searchType === 'posts' ? 'active' : ''} 
                                onClick={() => navigate(`/search?q=${searchQuery}&type=posts`)}
                            >
                                Posts
                            </button>
                            <button 
                                className={searchType === 'comments' ? 'active' : ''} 
                                onClick={() => navigate(`/search?q=${searchQuery}&type=comments`)}
                            >
                                Comments
                            </button>
                        </div>

                        {loading ? (
                            <div className="loading-container">
                                <div className="loading-spinner"></div>
                                <p>Searching...</p>
                            </div>
                        ) : results.length > 0 ? (
                            <div className="search-results-list">
                                {results.map((result, index) => (
                                    <div 
                                        key={index} 
                                        className={
                                            result.type === 'user' 
                                                ? 'user-result-card' 
                                                : result.type === 'comment'
                                                    ? 'comment-result-card'  // New class for comments
                                                    : 'community-card'
                                        }
                                    >
                                        {renderResult(result)}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="no-results">
                                <p>No results found for "{searchQuery}"</p>
                            </div>
                        )}
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default SearchResults;
